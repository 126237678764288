import React, { useState, useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { NavLink } from "react-router-dom";

import { Auth } from "aws-amplify";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";

import ConfirmSignUp from "../components/ConfirmSignUp";
import LoadingButton from "../components/LoadingButton";
import ShowPasswordAdornment from "../components/ShowPasswordAdornment";
import HeaderLogoSnackBar from "../components/HeaderLogoSnackBar";

import { getExtendedUserDataAsync, reSync, checkSync } from "../lib/userLib";
import { secretServiceLogin } from "../lib/serviceSignIn";
import wilcomStyles from "../theme/WilcomStyles";
import { retrieveCognitoUserEmail } from "../lib/userLib";
import { ErrorContext } from "../reducer/errorReducer";
import SignInPageUserDialogue from "../components/SignInPageUserDialogue";
import CustomerReview from "../components/CustomerReview";
import SignInModalRightBar from "../components/SignInModalRightBar";

const useStyles = makeStyles(wilcomStyles);

export default function SignIn(props) {
  const classes = useStyles();
  const { dispatch, showMessage } = useContext(ErrorContext);
  const [showConfirmUser, setShowConfirmUser] = useState(false);
  const [currentUsername, setCurrentUsername] = useState("");
  const [existingUserEmail, setExistingUserEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showExistingUserDialogue, setShowExistingUserDialogue] = useState(
    false
  );
  const { register, handleSubmit, errors } = useForm();

  // useEffect for clean timer when dismount;
  // would cause loop if create function and it will be new function every render
  useEffect(() => {
    return () => {
      if (window.timer) clearTimeout(window.timer);
      dispatch({ type: "hide" });
    };
  }, [dispatch]);

  function CompleteSignIn(username) {
    getExtendedUserDataAsync(username)
      .then(extendedData => {
        console.log({ extendedData });
        if (!extendedData) {
          showMessage(
            "sorry but there is an issue with your account.",
            "error"
          );
        } else {
          secretServiceLogin().then(() => {
            window.location = "/";
          });
        }
      })
      .catch(err => {
        console.log(err, err.stack);
        showMessage(err.message, "error");
        /* showSnackNotification(err.message); */
        setIsLoading(false);
      });
  }

  function confirmationSuccessful(success) {
    if (success) {
      setShowConfirmUser(false);
      showMessage(
        `Registration successful! For security reasons, you will be asked to sign in to continue.`,
        "success"
      );
    }
  }

  function onCloseExistingUserDialogue(result) {
    console.log({ onCloseExistingUserDialogue: result });
    setShowExistingUserDialogue(false);
    if (result) {
      console.log({ onCloseExistingUserDialogue: "SignUp" });
      window.location.href = "/signup";
    }
  }

  async function onSubmit(data, event) {
    event.preventDefault();

    const formData = new FormData(event.target);
    let email = formData.get("email").trim();
    const password = formData.get("password").trim();

    setCurrentUsername(email);
    setIsLoading(true);

    if (/@.+\./.test(email)) {
      try {
        const res = await retrieveCognitoUserEmail(email);
        console.log(res);
        if (res.inCognito) {
          email = res.email;
        }
      } catch (err) {
        showMessage(err.message, "error");
        setIsLoading(false);
        return;
      }
    }

    Auth.signIn(email, password)
      .then(user => {
        console.log({ user });
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          //window.location = "/updatepassword";
        }
        reSync(password).finally(() => CompleteSignIn(user.username));
      })
      .catch(err => {
        console.log({ err });
        if (err.code === "NotAuthorizedException") {
          if (err.message === "Password attempts exceeded") {
            showMessage(
              "Attempt limit exceeded, please try again in 30 minutes.",
              "error"
            );
          } else {
            showMessage(
              "Email address does not match password",
              "error"
            );
          }
          setIsLoading(false);
          return;
        }

        if (err.code === "UserNotConfirmedException") {
          setShowConfirmUser(true);
          setIsLoading(false);
          return;
        }

        if (err.code === "UserNotFoundException") {
          checkSync({ email: email, username: email })
            .then(response => {
              console.log({ response });
              if (
                !!response.checkResponse &&
                response.checkResponse.status === "unconfirmed"
              ) {
                setCurrentUsername(response.checkResponse.username);
                Auth.resendSignUp(response.checkResponse.username)
                  .then(() => {
                    setShowConfirmUser(true);
                    showMessage(
                      "Code resent, please check your email",
                      "success"
                    );
                    setTimeout(() => {
                      setIsLoading(false);
                    }, 1000);
                    return;
                  })
                  .catch(err => {
                    if (err.code === "LimitExceededException") {
                      showMessage(
                        "Attempt limit exceeded, please try again in 30 minutes.",
                        "error"
                      );
                      console.log(err);
                    } else {
                      showMessage(err.message, "error");
                      console.log(err);
                    }
                    setIsLoading(false);
                  });
              } else if (
                !!response.checkResponse &&
                response.checkResponse.userExists &&
                !response.checkResponse.field
              ) {
                setExistingUserEmail(email);
                setShowExistingUserDialogue(true);
              } else {
                showMessage(
                  `Login failed.
                   Please ensure that BOTH are using the correct upper and lowercase characters.`,
                  "error"
                );
                setIsLoading(false);
              }
            })
            .catch(error => {
              showMessage(error.message, "error");
            })
            .finally(() => setIsLoading(false));
        } else {
          showMessage(err.message, "error");
          setIsLoading(false);
        }
      });
  }

  const confirmationDialogue = (
    <ConfirmSignUp
      username={currentUsername}
      setShowConfirmUser={setShowConfirmUser}
      confirmationSuccessful={confirmationSuccessful}
    />
  );

  return showConfirmUser ? (
    confirmationDialogue
  ) : (
    <Container
      component="main"
      className={`${classes.mainContainer} ${classes.signInContainer}`}>
      <CssBaseline />
      <div style={{ display: "flex", height: "100%" }} >
        <div
          className={`${classes.paper} ${classes.signInForm}`}
        >

          <HeaderLogoSnackBar
            customClass={classes.snackbarCustomClass}
            showCloseButton={false}
          />

          <Typography
            color="primary"
            component="h1"
            className={`${classes.formHeading} ${classes.signInMainHeading}`}
          >
            Welcome back to<br />Hatch Embroidery
          </Typography>
          <Typography
            color="primary"
            component="h3"
            className={`${classes.formHeading} ${classes.signInSubHeading}`}
          >
            Sign in to your account
          </Typography>
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <TextField
              variant="outlined"
              margin="normal"
              error={errors.hasOwnProperty("email")}
              fullWidth
              id="email"
              label="Email"
              name="email"
              inputRef={register({
                required: "Email address required",
                validate: value =>
                  !/^ +$/.test(value) || "Email address required",
              })}
              autoComplete="email"
              autoFocus
            />
            {errors.email && (
              <p id="emailError" className={classes.signUpError}>
                {" "}
                {"* " + errors.email.message}{" "}
              </p>
            )}
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              error={errors.hasOwnProperty("password")}
              name="password"
              inputRef={register({ required: "Password required" })}
              InputProps={{
                endAdornment: 
                  <ShowPasswordAdornment 
                    showPassword={showPassword} 
                    setShowPassword={setShowPassword} 
                  />
              }}
              label="Password"
              type={!showPassword ? "password" : "text"}
              id="password"
              autoComplete="current-password"
            />
            {errors.password && (
              <p id="passwordError" className={classes.signUpError}>
                {" "}
                {"* " + errors.password.message}{" "}
              </p>
            )}
            <LoadingButton
              type="submit"
              id="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.signInButton}
              buttonText="Sign in"
              isLoading={isLoading}
            />
            <Button
              size="small"
              color="primary"
              className={`${classes.signInButton} ${classes.underlineButton}`}
              component={NavLink}
              to="/resetpassword"
            >
              {"Forgot password?"}
            </Button>
          </form>

          <SignInPageUserDialogue
            show={showExistingUserDialogue}
            existingUserEmail={existingUserEmail}
            onResponse={onCloseExistingUserDialogue}
          />
        </div>

        <SignInModalRightBar
          imageUrl="/sign-in-image.png"
          bulletPoints={[
            "Master embroidery in Hatch Academy",
            "Meet others in the Hatch Facebook group",
            "Find inspiration on the Hatch Blog"
          ]}
          reviewComponent={
            <CustomerReview
              reviewContent={`"Perfection is the title! Excellent customer service, the software? It's simply amazing!"`}
              reviewerName="Johanna Arias McCabe"
              reviewerTitle="Hobby Embroiderer"
              imageUrl="/reviewer-photo.png"
              starsImageUrl="/5-stars.svg"
            />
          }
        />
      </div>
    </Container>
  );
}
