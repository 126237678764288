import React from "react";
import { Auth } from "aws-amplify";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import HatchHeaderLogo from "../components/HatchHeaderLogo";
import { signOutUrl, landingPage } from "../config/sso-config";
import wilcomStyles from "../theme/WilcomStyles";
const useStyles = makeStyles(wilcomStyles);

export default function SignOut(props) {
  const classes = useStyles();
  setTimeout(() => {
    Auth.signOut()
      .then(data => console.log(data))
      .catch(err => console.log(err))
      .finally(data => (window.location = landingPage.store));
  }, 1000);

  return (
    <Container component="main" className={classes.mainContainer} maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <HatchHeaderLogo />
        <Typography component="h1" variant="h5">
          Signing Out ...
        </Typography>
        <CircularProgress className={classes.progress} />
        <div className={classes.SignoutFrames}>
          <iframe title="signOutAcademy3" src={signOutUrl.academy3} />
          <iframe title="signOutStore" src={signOutUrl.store} />
          <iframe title="signOutCommercialSite" src={signOutUrl.dnn} />
        </div>
      </div>
    </Container>
  );
}
