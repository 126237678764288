import React, { useContext, useEffect } from "react";
import { ErrorContext } from "../reducer/errorReducer";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";

import { processRedirect } from "../lib/redirectLib";
import HatchHeaderLogo from "../components/HatchHeaderLogo";
import SessionTable from "../components/SessionTable";
import isAuthorized from "../components/IsAuthorized";
import WilcomIdAppBar from "../components/WilcomIdAppBar";
import useSignInStatus from "../hooks/useSignInStatus";
import wilcomStyles from "../theme/WilcomStyles";
const useStyles = makeStyles(wilcomStyles);

export default function Home(props) {
  const classes = useStyles();
  const { isSignedIn, userName } = useSignInStatus();
  const { dispatch, showMessage } = useContext(ErrorContext);
  useEffect(() => {
    return () => {
      if (window.timer) clearTimeout(window.timer);
      dispatch({ type: "hide" });
    };
  }, [dispatch]);
  //test: http://localhost:3000/?return_url=http://www.abc.net.au
  processRedirect();

  function onNotify(notification, type) {
    showMessage(notification, type);
  }

  const authorizedComponent = (
    <div>
      <WilcomIdAppBar isSignedIn={isSignedIn} userName={userName} />
      <Container
        component="main"
        className={classes.mainContainer}
        maxWidth="lg"
      >
        <CssBaseline />
        <div className={classes.paper}>
          <HatchHeaderLogo size="sm" />
          <br />
          <h2>Hatch 3 Sessions</h2>
          <p style={{ textAlign: "center" }}>
            The devices you’re signed in to are listed below. If you see
            unfamiliar devices or locations, click “End Session” and then change
            your password. 
            <br/>
            You can be signed in to a maximum of 2 devices but
            can use Hatch software on 1 device only.
            <br />
            If you wish to sign into Hatch on a different computer, you can
            click 'Sign out and release license' from the File menu in the
            active Hatch software.
          </p>
          <br />
          <SessionTable onNotify={onNotify} />
        </div>
      </Container>
    </div>
  );

  return isAuthorized({ authorizedComponent, props });
}
