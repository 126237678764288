import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ErrorContext } from "../reducer/errorReducer";
import { Auth } from "aws-amplify";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import CardMedia from "@material-ui/core/CardMedia";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import Typography from "@material-ui/core/Typography";
import wilcomStyles from "../theme/WilcomStyles";
import LoadingButton from "../components/LoadingButton";
import HeaderLogoSnackBar from "./HeaderLogoSnackBar";

const useStyles = makeStyles(wilcomStyles);

export default function ConfirmSignUp(props) {
  console.log(props);
  const {
    username,
    confirmationSuccessful,
  } = props;
  const { dispatch, showMessage, state } = useContext(ErrorContext);

  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(true);

  const { show } = state;

  const { errors, register, handleSubmit } = useForm();

  // useEffect for clean timer when dismount;
  useEffect(() => {
    return () => {
      if (window.timer) clearTimeout(window.timer);
      dispatch({ type: "hide" });
    };
  }, [dispatch]);

  function handleTextInputChange(event) {
    const targetValue = event.currentTarget.value;
    // Disable submit button if no code is input
    setDisableSubmit(targetValue.length <= 0);
  }

  async function handleSubmitConfirmation(data, event) {
    event.preventDefault();

    if (!confirmed) {
      const formData = new FormData(event.target);
      const confirmationCode = formData.get("confirmationCode");
      setIsLoading(true);

      try {
        const confirmSignUpResponse = await Auth.confirmSignUp(username, confirmationCode);
        console.log({ confirmSignUpResponse });

        console.log(data);
        setConfirmed(true);
        confirmationSuccessful(true);
      } catch (confirmSignUpError) {
        console.error({ confirmSignUpError });
        if (confirmSignUpError.code === "CodeMismatchException") {
          showMessage(
            `Confirmation code incorrect,
             Check your email inbox or go back to request a new code.`,
            "error"
          );
          setIsLoading(false);
          return;
        }
        showMessage(confirmSignUpError.message, "error");
        setIsLoading(false);
      }
    }
  }

  async function resendConfirmationCode(event) {
    event.preventDefault();

    try {
      const resendSignUpResponse = await Auth.resendSignUp(username);
      console.log({ resendSignUpResponse });

      showMessage("Code resent, please check your email", "success");
    } catch (resendSignUpCodeError) {
      console.error({ resendSignUpCodeError });

      if (resendSignUpCodeError.code === "LimitExceededException") {
        showMessage(
          "Attempt limit exceeded, please try again in 30 minutes.",
          "error"
        );
        return;
      }
      showMessage(resendSignUpCodeError.message, "error");
    }
  }

  const snackbarWrapperStyle = {
    paddingBottom: show ? 44 : 0
  };

  return (
    <Container
      component="main"
      className={`${classes.mainContainer} ${classes.confirmSignUpContainer}`}
      maxWidth="xs"
    >
      <CssBaseline />
      <div
        className={classes.paper}
        style={{ padding: "44px" }}
      >
        <div
          style={snackbarWrapperStyle}
          className={classes.snackbarWrapper}
        >
          <HeaderLogoSnackBar
            customClass={classes.snackbarCustomClass}
            showCloseButton={false}
          />
        </div>
        <div className={classes.confirmSignUpHeadingWrapper}>
          <Typography
            component="h1"
            color="primary"
            className={classes.confirmSignUpHeading}
          >
            Check your email
          </Typography>
          <img src="/hatch-bullet.svg" alt="Hatch bullet" width="29" />
        </div>

        <CardMedia
          className={classes.media}
          image="/wilcom-logo.png"
          title="Wilcom International"
        />
        <Typography component="p" className={classes.confirmCardText}>
          An email was sent to you with your confirmation code
        </Typography>
        <form
          className={classes.form}
          onSubmit={handleSubmit(handleSubmitConfirmation)}
        >
          <Typography
            component="p"
            className={`${classes.confirmCardText} ${classes.enterCodeText}`}
          >
            Enter confirmation code
          </Typography>
          <TextField
            variant="outlined"
            margin="none"
            inputProps={{
              className: classes.codeInputText,
            }}
            disabled={isLoading}
            onChange={handleTextInputChange}
            className={classes.confirmCodeInput}
            fullWidth
            id="confirmationCode"
            name="confirmationCode"
            error={errors.hasOwnProperty("confirmationCode")}
            inputRef={register({
              required: "Please enter your confirmation code"
            })}
            autoFocus
          />
          {errors.confirmationCode && (
            <p className={classes.signUpError}>
              {" "}
              {"* " + errors.confirmationCode.message}{" "}
            </p>
          )}
          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            disabled={disableSubmit}
            color="primary"
            className={`${classes.submit} ${classes.resendCodeSubmitButton}`}
            buttonText="Submit code"
            isLoading={isLoading}
          />
          <Typography
            component="p"
            className={`${classes.confirmCardText} ${classes.resendCodeText}`}
          >
            If it hasn't arrived within 2 minutes, click "Resend code"<br />
            Remember to also check your spam inbox.
          </Typography>
          <Button
            type="submit"
            size="small"
            color="primary"
            className={classes.resendCodeButton}
            onClick={resendConfirmationCode}
          >
            Resend code
          </Button>
        </form>
      </div>
    </Container>
  );
}
