import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/styles";
import wilcomStyles from "../theme/WilcomStyles";

const useStyle = makeStyles(wilcomStyles);

export default function SignInPageUserDialogue(props) {
  const { show, existingUserEmail, onResponse } = props;
  const onAgree = () => onResponse(true);
  const onDisgree = () => onResponse(false);
  const classes = useStyle();
  return (
    <React.Fragment>
      <Dialog
        open={show}
        onClose={onDisgree}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Ready to migrate your old Hatch Embroidery account and register a{" "}
          <b>
            <i>NEW ACCOUNT</i>
          </b>{" "}
          ?
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            className={classes.existingUserDialog}
          >
            The email address{" "}
            <span>
              <i>{existingUserEmail}</i>
            </span>{" "}
            is registered on our old website.
            <br />
            <br />
            You will need to migrate it to our new site by completing the{" "}
            <u>NEW REGISTRATION FORM</u> with this <u>SAME EMAIL ADDRESS</u>.
            <br />
            <br />
            <span>PLEASE NOTE</span> – after completing this migration, the new
            password you create will be required when using your Hatch
            Embroidery software.
            <br />
            <br /> Do you wish to continue the migration and register your{" "}
            <span>
              <i>NEW ACCOUNT</i>
            </span>
            ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onDisgree} color="primary">
            NO
          </Button>
          <Button
            onClick={onAgree}
            color="primary"
            variant="contained"
            autoFocus
          >
            YES
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
