import React from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import wilcomStyles from "../theme/WilcomStyles";

const useStyles = makeStyles(wilcomStyles);

export default function LoadingButton(props) {
  const classes = useStyles();
  const { buttonText, isLoading, ...rest } = props;
  return (
    <Button { ...rest }>
      {!isLoading ? (
        buttonText
      ) : (
        <CircularProgress className={classes.buttonSpinner} variant="indeterminate" size={24} thickness={4} />
      )}
    </Button>
  );
}
