import React, { useContext } from "react";
// import { useState } from "react"

import clsx from "clsx";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import WarningIcon from "@material-ui/icons/Warning";
import IconButton from "@material-ui/core/IconButton";

import { amber, blue } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import Fade from "@material-ui/core/Fade";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { ErrorContext } from "../reducer/errorReducer";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const successColor = "#05A558";

const useBarStyles = makeStyles(theme => ({
  success: {
    backgroundColor: successColor
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: blue[600]
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 28
  },
  closeButton: {
    padding: 0,
    justifySelf: "flex-end"
  },
  closeIcon: {
    fontSize: 28
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: "flex",
    alignItems: "center",
    width: "inherit",
    whiteSpace: 'pre-line'
  },
  text: {
    flexGrow: 4
  },
  snackbarMessage: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%"
  }
}));

export default function HeaderLogoSnackBar(props) {
  const classes = useBarStyles();
  const { state, closeMessage } = useContext(ErrorContext);
  const { show, message, errorType } = state;

  const { customClass, showCloseButton, ...other } = props;

  const Icon = variantIcon[errorType];

  return (
    <React.Fragment>
      <Fade in={show} mountOnEnter unmountOnExit>
        <SnackbarContent
          style={{ width: "100%" }}
          className={clsx(classes[errorType], customClass)}
          aria-describedby="client-snackbar"
          message={
            <div style={{ width: "100%" }} className={classes.message}>
              <Icon className={clsx(classes.icon, classes.iconVariant)} />
              <div
                className={classes.snackbarMessage}
              >
                <div id="notificationMessage" className={classes.text}>{message}</div>
                {showCloseButton
                  && <IconButton
                    key="close"
                    aria-label="close"
                    color="inherit"
                    onClick={closeMessage}
                    className={classes.closeButton}
                  >
                    <CloseIcon className={classes.closeIcon} />
                  </IconButton>}
              </div>
            </div>
          }
          {...other}
        // id="notificationMessage"
        />
      </Fade>
    </React.Fragment>
  );
}

HeaderLogoSnackBar.defaultProps = {
  showCloseButton: true
}