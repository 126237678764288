import { Auth } from "aws-amplify";
import { useState } from "react";

export default function useSignInStatus() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [userName, setUserName] = useState("");
  const [userData, setUserData] = useState({});

  function mapUserData(cognitoUserSession) {
    const user = cognitoUserSession.idToken.payload;
    //console.log(user);
    return {
      firstName: user.name,
      familyName: user.family_name,
      email: user.email,
      userName: user["cognito:username"],
      phoneNumber: user.phone_number,
      sub: user.sub,
      locale: user.locale
    };
  }

  if (!isLoaded && !isLoading) {
    setIsLoading(true);
    Auth.currentSession()
      .then(data => {
        //console.log(data);
        const userData = mapUserData(data);
        setUserData(userData);
        setIsSignedIn(true);
        setUserName(userData.userName);
      })
      .catch(err => {
        console.log(`err ${err}`, err ? err.stack : err);
        setIsSignedIn(false);
        setUserName("");
      })
      .finally(data => {
        setIsLoaded(true);
      });

    // Ensure that update user data gets shown
    Auth.currentAuthenticatedUser({ bypassCache: true })
      .then(user => {
        const userData = mapUserData(user.signInUserSession);
        setUserData(userData);
        setIsSignedIn(true);
        setUserName(userData.userName);
      })
      .catch(err => {
        console.log(`err ${err}`, err ? err.stack : err);
        setIsSignedIn(false);
        setUserName("");
      })
      .finally(data => {
        setIsLoaded(true);
      });
  }

  return { isLoaded, isLoading, isSignedIn, userName, userData };
}
