// Context.js
import React from 'react'
import { createContext, useReducer, useCallback } from "react";

/* 
type: 
success: CheckCircleIcon,
warning: WarningIcon,
error: ErrorIcon,
info: InfoIcon, 
*/

const initialState = {
  show: false,
  message: "",
  errorType: "error"
}

let errorReducer = (state, action) => {
  switch (action.type) {
    case "show":
      return { ...state, show: true, ...action.payload };
    case "hide":
      return { ...state, show: false, ...action.payload };
    case "clean":
      return { ...state, show: false, message: '' }
    default:
      return state;
  }
};

const ErrorContext = createContext(initialState);

function ErrorProvider(props) {
  console.log('provider fired')
  const [state, dispatch] = useReducer(errorReducer, initialState);

  const showMessage = useCallback((message, errorType, time = 15000) => {
    console.log(window.timer)
    if (window.timer) clearTimeout(window.timer);
    dispatch({ type: "show", payload: { message, errorType } })
    window.timer = setTimeout(() => dispatch({ type: "hide" }), time)
  }, [dispatch])



  const closeMessage = useCallback(() => {
    console.log(window.timer)
    if (window.timer) clearTimeout(window.timer);
    dispatch({ type: "hide" })
  }, [dispatch])

  return (
    <ErrorContext.Provider value={{ state, dispatch, showMessage, closeMessage }}>
      {props.children}
    </ErrorContext.Provider>
  );
}

export { ErrorContext, ErrorProvider };