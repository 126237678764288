import { API, Auth } from "aws-amplify";
import { cognitoApiDefautHeaders } from "./authLib";

// export async function getCustomerRefId() {
//   const apiName = "wilcomIdApi";
//   const path = `/license/getCustomerRefId`;
//   const myInit = {
//     headers: cognitoApiDefautHeaders(await Auth.currentSession())
//   };
//   return await API.get(apiName, path, myInit);
// }

export async function getUserSessions() {
  let apiName = "wilcomIdApi";
  let path = "/license/getUserSessions";
  let myInit = {
    headers: cognitoApiDefautHeaders(await Auth.currentSession()),
    body: {}
  };
  return await API.post(apiName, path, myInit);
}

export async function getUserClearSessions() {
  let apiName = "wilcomIdApi";
  let path = "/license/getUserClearSessions";
  let myInit = {
    headers: cognitoApiDefautHeaders(await Auth.currentSession()),
    body: {}
  };
  return await API.post(apiName, path, myInit);
}

export async function clearUserSession(sessionid) {
  let apiName = "wilcomIdApi";
  let path = "/license/clearUserSession";
  let myInit = {
    headers: cognitoApiDefautHeaders(await Auth.currentSession()),
    body: {sessionid}
  };
  console.log({ myInit });

  return await API.post(apiName, path, myInit);
}

