import { createMuiTheme } from "@material-ui/core/styles";
import { themeConfig } from "../config/theme-config";

const overidePrimaryColor = !!themeConfig.primaryColorOverride;
const HATCH_BLUE = "#256B95";
const HATCH_SECONDARY_BLUE = "#59C6C8";

export function createWilcomIdMuiTheme() {
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: overidePrimaryColor
          ? themeConfig.primaryColorOverride
          : HATCH_BLUE,
        contrastText: "#ffffff"
      },
      secondary: {
        main: overidePrimaryColor ? HATCH_BLUE : HATCH_SECONDARY_BLUE
      },
      common: {
        lightGrey: "#F6F7F9",
        darkGrey: "#B2B2B2"
      }
    },
    typography: {
      button: {
        textTransform: "none"
      }
    }
  });
  return theme;
}
