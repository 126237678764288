import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import CardMedia from "@material-ui/core/CardMedia";
import Link from "@material-ui/core/Link";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import wilcomStyles from "../theme/WilcomStyles";
import { landingPage } from "../config/sso-config";

const useStyles = makeStyles(wilcomStyles);

export default function WilcomIdAppBar(props) {
  const { isSignedIn } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.wilcomAppBar}>
        <Toolbar>
          <Link href={landingPage.store} className={classes.link}>
            <CardMedia
              className={classes.menuIconLogo}
              image="/hatch-icon.png"
              title="Return to the Hatch Embroidery website"
            />
          </Link>
          <Typography
            variant={isSignedIn ? "h6" : "h5"}
            className={isSignedIn ? classes.title : classes.titleAnon}
          >
            My Hatch Account
          </Typography>
          {isSignedIn ? (
            <Fragment>
              <Button
                size="small"
                color="primary"
                variant="contained"
                className={classes.signOutButton}
              >
                <Link href="/signout" className={classes.menuLink}>
                  Sign out
                </Link>
              </Button>
            </Fragment>
          ) : null}
        </Toolbar>
      </AppBar>
    </div>
  );
}
