import React, { useEffect, useState, useRef, useCallback } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from "@material-ui/core/TextField";
import Menu from '@material-ui/core/Menu';
import InputAdornment from '@material-ui/core/InputAdornment';
import Radio from "@material-ui/core/Radio";
import RadioGroup from '@material-ui/core/RadioGroup';
import Button from "@material-ui/core/Button";

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import wilcomStyles from "../theme/WilcomStyles";

const useStyles = makeStyles(wilcomStyles);

const hatchDiscoveryOptions = [
  "Search engine (Google, Bing, etc.)",
  "Social media",
  "Recommended from a friend",
  "Reading an article",
  "Online advertisement",
];

export default function SignUpDiscoveryMenu({ error, onChange }) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(-2);
  const [selectedRadio, setSelectedRadio] = useState("");
  const [otherTextInput, setOtherTextInput] = useState("");
  const [otherText, setOtherText] = useState("");

  const otherTextInputRef = useRef(null);

  // Need to memoize this to include it in useEffect dependency array without rendering on every refresh
  const textFieldValue = useCallback(() => {
    if (selectedIndex === -1) {
      return otherText ? `Other: ${otherText}` : "";
    }

    return selectedIndex >= 0 ? hatchDiscoveryOptions[selectedIndex] : "";
  }, [selectedIndex, otherText])

  useEffect(() => {
    onChange(textFieldValue());
  }, [selectedIndex, otherText, onChange, textFieldValue]);

  function handleClickListItem(event) {
    setAnchorEl(event.currentTarget);
  };

  function handleMenuItemClick(event, index) {
    setSelectedIndex(index);
    setSelectedRadio(`radio-${index}`);
    setOtherTextInput("");
    setOtherText("");
    setAnchorEl(null);
  };

  function handleOtherClick() {
    setSelectedIndex(-1);
    setSelectedRadio(`radio-other`);
    // Should act like a Vue "nextTick" // No to React
    setTimeout(() => otherTextInputRef.current.focus());
  }

  function handleRadioClick(event) {
    setSelectedRadio(event.currentTarget.value)
  }

  function handleClose() {
    if (selectedIndex === -1) {
      setOtherTextInput("");
      setSelectedRadio("");
    }
    setAnchorEl(null);
  };

  function handleOtherButtonClick() {
    setOtherText(otherTextInput);
    setOtherTextInput("");
    setSelectedRadio("");
    setAnchorEl(null);
  }

  function showPleaseSelectText() {
    return textFieldValue() === "" && !!anchorEl;
  }

  const chevronIcon = !!anchorEl ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />;

  return (
    <>
      <TextField
        id="discovery"
        name="discovery"
        label="How did you find us?"
        variant="outlined"
        value={!showPleaseSelectText() ? textFieldValue() : "Please select"}
        focused={!!anchorEl}
        error={error}
        readOnly
        inputProps={{
          style: {
            cursor: "pointer",
            color: !showPleaseSelectText() ? "black" : "#989898",
          },
        }}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment
              position='end'
              className={classes.signUpDiscoveryMenuAdornment}
            >
              {chevronIcon}
            </InputAdornment>
          ),
        }}
        onClick={handleClickListItem}
      />

      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        keepMounted
        dense="true"
        open={Boolean(anchorEl)}
        PaperProps={{
          className: classes.signUpDiscoveryMenuPaper,
        }}
        onClose={handleClose}
      >
        {/* TODO: Use custom MUI class to make radio primary colour */}
        <RadioGroup value={selectedRadio} onChange={handleRadioClick}>
          {hatchDiscoveryOptions.map((option, index) => (
            <MenuItem
              className={classes.signUpDiscoveryMenuItem}
              key={option}
              selected={index === selectedIndex}
              onClick={(event) => handleMenuItemClick(event, index)}
            >
              <Radio
                id={`radio-${index}`}
                value={`radio-${index}`}
                size="small"
                color="primary"
              />
              {option}
            </MenuItem>
          ))}
          <MenuItem
            className={classes.signUpDiscoveryMenuItem}
            selected={selectedRadio === "radio-other"}
            onClick={handleOtherClick}
          >
            <Radio
              id={"radio-other"}
              value={"radio-other"}
              size="small"
              color="primary"
            />
            Other
          </MenuItem>
        </RadioGroup>
        {selectedRadio === "radio-other" &&
            <div
              className={classes.signUpDiscoveryMenuOtherForm}
            >
              <TextField
                placeholder="Please specify"
                inputProps={{
                  ref: otherTextInputRef
                }}
                value={otherTextInput}
                onKeyDown={(event) => event.stopPropagation()}
                onChange={(event) => setOtherTextInput(event.target.value)}
              />
              <Button
                style={{ width: "68px", marginTop: "15px" }}
                variant="contained"
                color="primary"
                onClick={handleOtherButtonClick}
              >
                Done
              </Button>
            </div>
          }
      </Menu >
    </>
  );
}