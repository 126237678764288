import { API, Auth } from "aws-amplify";
import { cognitoApiDefautHeaders } from "./authLib";

export async function getExtendedUserDataAsync(username) {
  const apiName = "wilcomIdApi";
  const path = `/user/extendeduserdata/${window.encodeURIComponent(username)}`;
  const myInit = {
    headers: cognitoApiDefautHeaders(await Auth.currentSession())
  };
  return await API.get(apiName, path, myInit);
}

export async function postExtendedUserDataAsync(extendedUserData) {
  let apiName = "wilcomIdApi";
  let path = "/user/extendeduserdata";
  let myInit = {
    headers: cognitoApiDefautHeaders(await Auth.currentSession()),
    body: extendedUserData
  };
  return await API.post(apiName, path, myInit);
}

export async function putExtendedUserDataAsync(extendedUserData) {
  let apiName = "wilcomIdApi";
  let path = "/user/extendeduserdata";
  let myInit = {
    headers: cognitoApiDefautHeaders(await Auth.currentSession()),
    body: extendedUserData
  };
  return await API.put(apiName, path, myInit);
}

export async function updateUserProfileAsync(userData, extendedUserData) {
  let apiName = "wilcomIdApi";
  let path = "/user/updateuserprofile";
  let myInit = {
    headers: cognitoApiDefautHeaders(await Auth.currentSession()),
    body: { userData, extendedUserData }
  };
  console.log({ myInit });

  return await API.put(apiName, path, myInit);
}

export async function validateExistingAccount(userData) {
  let apiName = "wilcomIdApi";
  let path = "/user/validateexistingaccount";
  let myInit = {
    //headers: cognitoApiDefautHeaders(await Auth.currentSession()),
    body: userData
  };
  console.log({ myInit });

  return await API.post(apiName, path, myInit);
}

export async function getUserGeo(userData) {
  let apiName = "wilcomIdApi";
  let path = "/getgeo";
  let myInit = {
    //headers: cognitoApiDefautHeaders(await Auth.currentSession()),
    //body: userData
  };
  console.log({ myInit });

  return await API.get(apiName, path, myInit);
}

export async function checkSync(userData) {
  let apiName = "wilcomIdApi";
  let path = "/checksync";
  let myInit = {
    //headers: cognitoApiDefautHeaders(await Auth.currentSession()),
    body: userData
  };
  console.log({ myInit });

  return await API.post(apiName, path, myInit);
}

export async function serviceSync(userData) {
  let apiName = "wilcomIdApi";
  let path = "/servicesync";
  let myInit = {
    //headers: cognitoApiDefautHeaders(await Auth.currentSession()),
    //body: userData
  };
  console.log({ myInit });

  return await API.get(apiName, path, myInit);
}

export async function reSync(userData) {
  let apiName = "wilcomIdApi";
  let path = "/resync";
  let myInit = {
    headers: cognitoApiDefautHeaders(await Auth.currentSession()),
    body: { userData }
  };
  console.log({ myInit });

  return await API.post(apiName, path, myInit);
}

export async function updateUserReferrals(referrer) {
  let apiName = "wilcomIdApi";
  let path = "/user/updatereferrals";
  let myInit = {
    headers: cognitoApiDefautHeaders(await Auth.currentSession()),
    body: { referrer }
  };
  console.log({ myInit });

  return await API.post(apiName, path, myInit);
}

export async function retrieveCognitoUserEmail(email) {
  let apiName = "wilcomIdApi";
  let path = "/user/retrievecognitouseremail";
  let myInit = {
    body: { email }
  };
  console.log({ myInit });

  return await API.post(apiName, path, myInit);
}

export async function checkHasFlexpayAccount() {
  return await API.get("wilcomIdApi", "/hasFlexpayAccount", {
    headers: cognitoApiDefautHeaders(await Auth.currentSession()),
  });
}

export async function initiateEmailChangeAsync(newEmail) {
  let myInit = {
    headers: cognitoApiDefautHeaders(await Auth.currentSession()),
    body: { email: newEmail }
  };
  console.log({ myInit });
  return API.post("wilcomIdApi", "/user/initiateemailchange", myInit);
}

export async function confirmUserEmailChangeAsync(code) {
  const currentSession = await Auth.currentSession();
  let myInit = {
    headers: cognitoApiDefautHeaders(currentSession),
    body: { 
      accessToken: currentSession.getAccessToken().getJwtToken(),
      code,
     }
  };
  console.log({ myInit });
  return API.post("wilcomIdApi", "/user/confirmemailchange", myInit);
}

export async function validateAccountKey(userData) {
  let apiName = "wilcomIdApi";
  let path = "/user/validateAccountKey";
  let myInit = {
    body: userData
  };
  console.log({ myInit });

  return await API.post(apiName, path, myInit);
}