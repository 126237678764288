import React, { useState, useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { ErrorContext } from "../reducer/errorReducer";
import { Auth } from "aws-amplify";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { NavLink } from "react-router-dom";
import HeaderLogoSnackBar from '../components/HeaderLogoSnackBar';

import ConfirmSignUp from "../components/ConfirmSignUp";
import LoadingButton from "../components/LoadingButton";
import wilcomStyles from "../theme/WilcomStyles";

import { checkSync, retrieveCognitoUserEmail } from "../lib/userLib";
const useStyles = makeStyles(wilcomStyles);

export default function ResetPassword(props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmUser, setShowConfirmUser] = useState(false);
  const [currentUsername, setCurrentUsername] = useState("");
  const { errors, register, handleSubmit } = useForm();
  const { dispatch, showMessage, state } = useContext(ErrorContext);

  const { show } = state;

  useEffect(() => {
    return () => {
      if (window.timer) clearTimeout(window.timer);
      dispatch({ type: "hide" });
    };
  }, [dispatch]);

  async function onSubmit(data, event) {
    event.preventDefault();

    const formData = new FormData(event.target);
    let email = formData.get("email").trim();
    console.log({ email });

    setIsLoading(true);

    // Verify email
    if (/@.+\./.test(email)) {
      try {
        const res = await retrieveCognitoUserEmail(email);
        console.log(res);
        if (res.inCognito) {
          email = res.email;
        }
      } catch (err) {
        showMessage(err.message, "error");
        setIsLoading(false);
        return;
      }
    }

    // TODO refactor code update to EC6+
    try {
      const forgotPasswordResponse = await Auth.forgotPassword(email);
      console.log({ forgotPasswordResponse });

      setTimeout(() => (window.location = `/newpassword/${email}`), 1000);
    } catch (forgotPasswordError) {
      console.error({ forgotPasswordError });
      if (forgotPasswordError.code === "UserNotFoundException") {
        const checkSyncResponse = await checkSync({ email: email, username: email })
        console.log({ checkSyncResponse });

        if (
          !!checkSyncResponse.checkResponse &&
          checkSyncResponse.checkResponse.status === "unconfirmed"
        ) {
          try {
            setCurrentUsername(checkSyncResponse.checkResponse.username);
            await Auth.resendSignUp(checkSyncResponse.checkResponse.username)
            setShowConfirmUser(true);
            showMessage(
              "You have not confirmed your email yet, please enter the code to finalise the registration",
              "success"
            );
            setIsLoading(false);
            return;
          } catch (resendSignUpCodeError) {
            console.error({ resendSignUpCodeError });
            if (resendSignUpCodeError.code === "LimitExceededException") {
              showMessage(
                "Attempt limit exceeded, please try again in 30 minutes.",
                "error"
              );
              console.log(resendSignUpCodeError);
              setIsLoading(false);
              return;
            }
            showMessage(resendSignUpCodeError.message, "error");
            setIsLoading(false);
          }
        } else {
          showMessage(
            `Cannot proceed with your request.\nPlease ensure that you entered a correct email address.`,
            "error"
          );
          setIsLoading(false);
        }

        return;
      }
      if (forgotPasswordError.code === "LimitExceededException") {
        showMessage(
          `The password reset has been requested too many times.\nPlease wait and try again in 30 minutes.`,
          "error"
        );
        setIsLoading(false);
        return;
      }
      showMessage(forgotPasswordError.message || forgotPasswordError, "error");
      setIsLoading(false);
    }
  }

  function confirmationSuccessful(success) {
    if (success) {
      setShowConfirmUser(false);
      showMessage(
        `Registration successful! For security reasons, you will be asked to sign in to continue.`,
        "success"
      );
    }
  }

  const snackbarWrapperStyle = {
    paddingBottom: show ? 44 : 0
  };

  const confirmationDialogue = (
    <ConfirmSignUp
      username={currentUsername}
      setShowConfirmUser={setShowConfirmUser}
      confirmationSuccessful={confirmationSuccessful}
    />
  );

  return showConfirmUser ? (
    confirmationDialogue
  ) : (
    <Container
      component="main"
      className={`${classes.mainContainer} ${classes.confirmSignUpContainer}`}
      maxWidth="xs"
    >
      <CssBaseline />
      <div
        style={{ padding: "44px" }}
        className={classes.paper}
      >
        <div
          style={snackbarWrapperStyle}
          className={classes.snackbarWrapper}
        >
          <HeaderLogoSnackBar
            customClass={classes.snackbarCustomClass}
            showCloseButton={false}
          />
        </div>

        <Typography
          color="primary"
          component="h1"
          className={`${classes.formHeading}`}
          style={{
            fontSize: "24px",
            marginBottom: "30px"
          }}
        >
          Reset your password
        </Typography>

        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <div style={{ marginBottom: 28 }}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="email"
              label="Email"
              name="email"
              error={errors.hasOwnProperty("email")}
              inputRef={register({
                required: "Email address required",
                validate: value => !/^ +$/.test(value) || "Email address required"
              })}
              autoComplete="email"
              autoFocus
            />
            {errors.email && (
              <p id="emailError" className={classes.signUpError}>
                {" "}
                {"* " + errors.email.message}{" "}
              </p>
            )}
          </div>
          <LoadingButton
            type="submit"
            fullWidth
            id="submit"
            variant="contained"
            color="primary"
            style={{ height: 50, marginBottom: 34 }}
            className={classes.submit}
            buttonText="Reset Password"
            isLoading={isLoading}
          />
          <div
            className={classes.newPasswordButtonWrapper}
          >
            <div>
              <Button
                size="small"
                color="primary"
                component={NavLink}
                to="/signin"
                className={classes.underlineButton}
              >
                {"Back to Sign In"}
              </Button>
            </div>
            <div>
              <Button
                size="small"
                color="primary"
                component={NavLink}
                to="/newpassword"
                className={classes.underlineButton}
              >
                {"Already have a reset code?"}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Container >
  );
}
