export const ROUTES = {
    HOME: "/",
    CHANGE_EMAIL: "/changeemail",
    MANAGE_FLEXPAY: "/manageflexpay",
    NEW_PASSWORD: "/newpassword",
    PROFILE: "/profile",
    RESET_PASSWORD: "/resetpassword",
    SESSIONS: "/sessions",
    SIGN_IN: "/signin",
    SIGN_OUT: "/signout",
    SIGN_UP: "/signup",
};