import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import InputMask from "react-input-mask";
import { Country, State } from "country-state-city";
import countries from "i18n-iso-countries";

import {
  Button,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";
import { ErrorContext } from "../reducer/errorReducer";
import { updateFlexpayDetailsAsync } from "../lib/flexpayApiLib";

const VIEWS = {
  BILLING: "billing",
  CARD: "card",
};

function localStyles(theme) {
  return {
    sectionNumber: {
      backgroundColor: "#2F2F2F",
      color: "white",
      borderRadius: 10,
      width: 20,
      height: 20,
      textAlign: "center",
    },
    billingFieldLabel: {
      display: "flex",
      justifyContent: "space-between",
      color: "#5C5C5C",
    },
    requiredText: { fontSize: 12 },
    noMargin: {
      margin: 0,
      "& .MuiFormHelperText-root": {
        marginLeft: 0,
        marginTop: 3,
      },
    },
    formButtonsContainer: { marginTop: 20 },
  };
}

export default function UpdatePaymentMethod(updatePaymentProps) {
  const localClasses = makeStyles(localStyles)();

  const {
    register: registerBillingAddress,
    handleSubmit: handleSubmitBillingAddress,
    setValue: setValueBillingAddress,
    errors: billingAddressErrors,
    watch: watchBillingAddressValues,
    control: billingAddressControl,
  } = useForm();

  const {
    register: registerCC,
    handleSubmit: handleSubmitCC,
    errors: ccErrors,
    control: ccControl,
  } = useForm();

  const { showMessage } = useContext(ErrorContext);
  const [isUpdatingCardInfo, setIsUpdatingCardInfo] = useState(false);

  const [currentView, setCurrentView] = useState(VIEWS.BILLING);
  const [isPreviousBillingAddress, setIsPreviousBillingAddress] =
    useState(false);
  const [billingAddressInfo, setBillingAddressInfo] = useState(null);

  const [previousBillingList, setPreviousBillingList] = useState([]);
  const [allCountriesList, setAllCountriesList] = useState([]);
  const [allStatesList, setAllStatesList] = useState([]);

  useEffect(() => {
    setAllCountriesList(Country.getAllCountries());
    console.log({ updatePaymentProps });
    let { currentBillingInfo } = updatePaymentProps;
    console.log({ currentBillingInfo });
    // Need to make sure country is in ISO alpha-2 form for backend
    const alpha3ToAlpha2Mapping = countries.getAlpha3Codes();
    console.log({ alpha3ToAlpha2Mapping });
    const currentCountryCode = currentBillingInfo.country;
    console.log({ currentCountryCode });
    currentBillingInfo.country =
      currentCountryCode.length === 2
        ? currentCountryCode
        : alpha3ToAlpha2Mapping[currentCountryCode];

    setPreviousBillingList([currentBillingInfo]);
  }, [updatePaymentProps]);

  const onSubmitAddress = (billingAddressData) => {
    if (!!isPreviousBillingAddress) {
      setCurrentView(VIEWS.CARD);
      return;
    }
    // Just concat the address lines?
    billingAddressData.address = !!billingAddressData.address2
      ? `${billingAddressData.address} ${billingAddressData.address2}`
      : billingAddressData.address;
    delete billingAddressData.address2;

    console.log({ billingAddressData });
    setBillingAddressInfo(billingAddressData);
    setCurrentView(VIEWS.CARD);
  };

  const onSubmitCC = async (ccData) => {
    console.log({ billingAddressInfo });
    console.log({ ccData });
    delete ccData.nameOnCard;

    // is cardOnName needed?
    const expireMonth = ccData.expiryDate.split("/")[0];
    const expireYear = `20${ccData.expiryDate.split("/")[1]}`;
    const expirationDate = `${expireYear}-${expireMonth}`;
    console.log({ expirationDate });
    const updatePaymentRequestBody = {
      billingInfo: {
        // billingAddressInfo
        firstName: billingAddressInfo.firstName,
        lastName: billingAddressInfo.lastName,
        company: billingAddressInfo.company,
        address: billingAddressInfo.address,
        city: billingAddressInfo.city,
        state: billingAddressInfo.state,
        zip: billingAddressInfo.zip,
        country: billingAddressInfo.country,
        phoneNumber: billingAddressInfo.phoneNumber,
      },
      cardInfo: {
        // ...ccData,
        cardNumber: ccData.cardNumber.replaceAll(" ", ""),
        expirationDate: expirationDate,
        cardCode: `${ccData.cardCode}`,
      },
    };
    console.log({ updatePaymentRequestBody });

    console.log("SENDING API CALL TO VALIDATE CARD");
    try {
      setIsUpdatingCardInfo(true);
      const authNetValidationResponse = await updateFlexpayDetailsAsync(
        updatePaymentRequestBody
      );
      console.log({ authNetValidationResponse });

      if (authNetValidationResponse.messages.resultCode === "Ok") {
        showMessage("Success!", "success");
        window.location = "/manageflexpay";
      }

      // Manually set any errors from card validation
      if (authNetValidationResponse.messages.resultCode === "Error") {
        let allErrorMessages = "";
        for (const error of authNetValidationResponse.messages.message) {
          allErrorMessages += error.text;
        }
        showMessage(allErrorMessages, "error");
      }
    } catch (authNetValidationError) {
      console.error({ authNetValidationError });
      showMessage(authNetValidationError.message, "error");
    } finally {
      setIsUpdatingCardInfo(false);
    }
  };

  const handleCountryChange = (event) => {
    const selectedCountryISO = event[0]?.target?.value;
    console.log({ selectedCountryISO });
    console.log({ allStates: State.getStatesOfCountry(selectedCountryISO) });
    setBillingAddressInfo("country", selectedCountryISO);
    // alert(selectedCountryISO)
    setAllStatesList(State.getStatesOfCountry(selectedCountryISO));
    return selectedCountryISO;
  };

  const handleStateChange = (event) => {
    const selectedState = event[0].target.value;
    setBillingAddressInfo("state", selectedState);
    console.log({ selectedState });
    return selectedState;
  };

  const setBillingFormValues = (billingInfo) => {
    console.log({ billingInfo });
    for (const attribute in billingInfo) {
      billingInfo[attribute] = billingInfo[attribute]?.trim();
    }
    setValueBillingAddress("firstName", billingInfo.firstName);
    setValueBillingAddress("lastName", billingInfo.lastName);
    setValueBillingAddress("company", billingInfo.company);
    setValueBillingAddress("phoneNumber", billingInfo.phoneNumber);
    setValueBillingAddress("address", billingInfo.address);
    setValueBillingAddress("address2", billingInfo.address2);
    setValueBillingAddress("city", billingInfo.city);
    setValueBillingAddress("country", billingInfo.country);
    setValueBillingAddress("state", billingInfo.state);
    setValueBillingAddress("zip", billingInfo.zip);
  };

  const handleChangeSelectAddress = (event) => {
    const selectedAddress = event.target.value;
    console.log({ selectedAddress });
    setBillingFormValues(selectedAddress);
    setBillingAddressInfo(selectedAddress);
    setIsPreviousBillingAddress(true);
  };

  const backToEditingBillAddress = (event) => {
    setCurrentView(VIEWS.BILLING);
    setTimeout(() => {
      console.log({ billingAddressInfo });
      setBillingFormValues(billingAddressInfo);
    }, 50);
    // Allow the form to be edited again
    setIsPreviousBillingAddress(false);
  };

  return (
    <div style={{ width: "80%" }}>
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          marginBottom: 10,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex" }}>
          <div className={localClasses.sectionNumber}>1</div>
          &nbsp; &nbsp;
          <b>Billing Address</b>
        </div>
        {currentView !== VIEWS.BILLING && (
          <Button variant="outlined" onClick={backToEditingBillAddress}>
            Edit
          </Button>
        )}
      </Grid>

      {/* Display billing info form */}
      {currentView === VIEWS.BILLING && (
        <form onSubmit={handleSubmitBillingAddress(onSubmitAddress)}>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                margin="dense"
                label="Select previous address"
                onChange={handleChangeSelectAddress}
                select
                value={!isPreviousBillingAddress ? "" : billingAddressInfo}
                SelectProps={{
                  style: { fontSize: 14, padding: 2 },
                  MenuProps: {
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                  },
                  renderValue: (option) =>
                    !option ? (
                      ""
                    ) : (
                      <div>
                        <span>
                          {option.firstName} {option.lastName}
                        </span>
                        {(!!option.company || !!option.phoneNumber) && <br/>}
                        <span>
                          {!!option.company && <span>{option.company} / </span>}
                          {!!option.phoneNumber && <span>Phone: {option.phoneNumber}</span>}
                        </span>
                        <br />
                        <span>
                          {option.address}
                          {/* {option.address} {option.address2} */}
                        </span>
                        <br />
                        <span>
                          {option.city}, {option.state}, {option.zip} /{" "}
                          {option.country}
                        </span>
                      </div>
                    ),
                }}
              >
                {/* only one address */}
                {previousBillingList.map((billingInfo, index) => (
                  <MenuItem
                    key={index}
                    value={billingInfo}
                    style={{ fontSize: 12 }}
                  >
                    <div>
                      <span>
                        {billingInfo.firstName} {billingInfo.lastName}
                      </span>
                      <br />
                      {!!billingInfo.company && (
                        <>
                          <span>{billingInfo.company} </span>
                          <br />
                        </>
                      )}
                      {!!billingInfo.phoneNumber && (
                        <>
                          <span>{billingInfo.phoneNumber} </span>
                          <br />
                        </>
                      )}
                      <span>
                        {billingInfo.address}
                        {/* {billingInfo.address} {billingInfo.address2} */}
                      </span>
                      <br />
                      <span>
                        {billingInfo.city}, {billingInfo.state},{" "}
                        {billingInfo.zip} / {billingInfo.country}
                      </span>
                    </div>
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {!isPreviousBillingAddress && (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <div className={localClasses.billingFieldLabel}>
                      <span>First Name *</span>
                      <span className={localClasses.requiredText}>
                        REQUIRED
                      </span>
                    </div>
                    <TextField
                      className={localClasses.noMargin}
                      variant="outlined"
                      fullWidth
                      margin="dense"
                      name="firstName"
                      inputRef={registerBillingAddress({
                        required: "Required",
                        validate: (value) =>
                          value.trim().length < 1 ? "Required" : null,
                      })}
                      error={!!billingAddressErrors.firstName}
                      helperText={
                        !billingAddressErrors.firstName
                          ? ""
                          : billingAddressErrors.firstName.message
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <div className={localClasses.billingFieldLabel}>
                      <span>Last Name *</span>
                      <span className={localClasses.requiredText}>
                        REQUIRED
                      </span>
                    </div>
                    <TextField
                      className={localClasses.noMargin}
                      variant="outlined"
                      fullWidth
                      margin="dense"
                      name="lastName"
                      inputRef={registerBillingAddress({
                        required: "Required",
                        validate: (value) =>
                          value.trim().length < 1 ? "Required" : null,
                      })}
                      error={!!billingAddressErrors.lastName}
                      helperText={
                        !billingAddressErrors.lastName
                          ? ""
                          : billingAddressErrors.lastName.message
                      }
                    />
                  </Grid>
                </Grid>{" "}
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <div className={localClasses.billingFieldLabel}>
                      <span>Company Name</span>
                    </div>
                    <TextField
                      className={localClasses.noMargin}
                      variant="outlined"
                      fullWidth
                      margin="dense"
                      name="company"
                      inputRef={registerBillingAddress()}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <div className={localClasses.billingFieldLabel}>
                      <span>Phone Number</span>
                    </div>
                    <TextField
                      className={localClasses.noMargin}
                      variant="outlined"
                      fullWidth
                      margin="dense"
                      name="phoneNumber"
                      inputRef={registerBillingAddress()}
                      error={!!billingAddressErrors.phoneNumber}
                      helperText={
                        !billingAddressErrors.phoneNumber
                          ? ""
                          : billingAddressErrors.phoneNumber.message
                      }
                    />
                  </Grid>
                </Grid>{" "}
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <div className={localClasses.billingFieldLabel}>
                      <span>Address Line 1 *</span>
                      <span className={localClasses.requiredText}>
                        REQUIRED
                      </span>
                    </div>
                    <TextField
                      className={localClasses.noMargin}
                      variant="outlined"
                      fullWidth
                      margin="dense"
                      name="address"
                      inputRef={registerBillingAddress({
                        required: "Required",
                        validate: (value) =>
                          value.trim().length < 1 ? "Required" : null,
                      })}
                      error={!!billingAddressErrors.address}
                      helperText={
                        !billingAddressErrors.address
                          ? ""
                          : billingAddressErrors.address.message
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <div className={localClasses.billingFieldLabel}>
                      <span>Address Line 2</span>
                    </div>
                    <TextField
                      className={localClasses.noMargin}
                      variant="outlined"
                      fullWidth
                      margin="dense"
                      name="address2"
                      inputRef={registerBillingAddress()}
                    />
                  </Grid>
                </Grid>{" "}
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <div className={localClasses.billingFieldLabel}>
                      <span>Suburb/City *</span>
                      <span className={localClasses.requiredText}>
                        REQUIRED
                      </span>
                    </div>
                    <TextField
                      className={localClasses.noMargin}
                      variant="outlined"
                      fullWidth
                      margin="dense"
                      name="city"
                      inputRef={registerBillingAddress({
                        required: "Required",
                        validate: (value) =>
                          value.trim().length < 1 ? "Required" : null,
                      })}
                      error={!!billingAddressErrors.city}
                      helperText={
                        !billingAddressErrors.city
                          ? ""
                          : billingAddressErrors.city.message
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <div className={localClasses.billingFieldLabel}>
                      <span>Country *</span>
                      <span className={localClasses.requiredText}>
                        REQUIRED
                      </span>
                    </div>
                    <Controller
                      name="country"
                      control={billingAddressControl}
                      rules={{ required: { value: true, message: "Required" } }}
                      onChange={handleCountryChange}
                      as={
                        <TextField
                          select
                          className={localClasses.noMargin}
                          variant="outlined"
                          fullWidth
                          margin="dense"
                          name="country"
                          SelectProps={{
                            value: `${watchBillingAddressValues("country")}`,
                            MenuProps: {
                              style: { maxHeight: 400 },
                              getContentAnchorEl: null,
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                            },
                          }}
                          error={!!billingAddressErrors.country}
                          helperText={
                            !billingAddressErrors.country
                              ? ""
                              : billingAddressErrors.country.message
                          }
                        >
                          {allCountriesList.map((country) => (
                            <MenuItem
                              key={country.isoCode}
                              value={country.isoCode}
                            >
                              {country.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      }
                    />
                  </Grid>
                </Grid>{" "}
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <div className={localClasses.billingFieldLabel}>
                      <span>State/Province *</span>
                      <span className={localClasses.requiredText}>
                        REQUIRED
                      </span>
                    </div>
                    {allStatesList.length < 1 ? (
                      <TextField
                        className={localClasses.noMargin}
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        name="state"
                        inputRef={registerBillingAddress({
                          required: "Required",
                          validate: (value) =>
                            value.trim().length < 1 ? "Required" : null,
                        })}
                        error={!!billingAddressErrors.state}
                        helperText={
                          !billingAddressErrors.state
                            ? ""
                            : billingAddressErrors.state.message
                        }
                      />
                    ) : (
                      <Controller
                        name="state"
                        control={billingAddressControl}
                        rules={{
                          required: { value: true, message: "Required" },
                        }}
                        onChange={handleStateChange}
                        as={
                          <TextField
                            select
                            className={localClasses.noMargin}
                            variant="outlined"
                            fullWidth
                            margin="dense"
                            name="state"
                            SelectProps={{
                              value: `${watchBillingAddressValues("state")}`,
                              MenuProps: {
                                style: { maxHeight: 400 },
                                getContentAnchorEl: null,
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left",
                                },
                              },
                            }}
                            error={!!billingAddressErrors.state}
                            helperText={
                              !billingAddressErrors.state
                                ? ""
                                : billingAddressErrors.state.message
                            }
                          >
                            {allStatesList.map((state) => (
                              <MenuItem key={state.isoCode} value={state.name}>
                                {state.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        }
                      />
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <div className={localClasses.billingFieldLabel}>
                      <span>Zip/Postcode *</span>
                      <span className={localClasses.requiredText}>
                        REQUIRED
                      </span>
                    </div>
                    <TextField
                      className={localClasses.noMargin}
                      variant="outlined"
                      fullWidth
                      margin="dense"
                      name="zip"
                      inputRef={registerBillingAddress({
                        required: "Required",
                      })}
                      error={!!billingAddressErrors.zip}
                      helperText={
                        !billingAddressErrors.zip
                          ? ""
                          : billingAddressErrors.zip.message
                      }
                    />
                  </Grid>
                </Grid>
              </>
            )}
            <Grid container className={localClasses.formButtonsContainer}>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  disableelevation="true"
                  style={{ backgroundColor: "#276A95", color: "white" }}
                  type="submit"
                >
                  Next Step
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}

      <br />
      {currentView === VIEWS.CARD && <hr />}
      <Grid
        item
        xs={12}
        style={{ display: "flex", marginTop: 20, marginBottom: 20 }}
      >
        <div className={localClasses.sectionNumber}>2</div>
        &nbsp; &nbsp;
        <b>Edit Credit/Debit Card</b>
      </Grid>
      {/* Display Card form */}
      {currentView === VIEWS.CARD && (
        <form onSubmit={handleSubmitCC(onSubmitCC)}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <div>
                  <span>Credit Card Number</span>
                </div>
                <Controller
                  name="cardNumber"
                  control={ccControl}
                  rules={{ required: "Required" }}
                  as={
                    <InputMask mask="9999 9999 9999 9999">
                      {(inputProps) => (
                        <TextField
                          className={localClasses.noMargin}
                          variant="outlined"
                          fullWidth
                          margin="dense"
                          error={!!ccErrors.cardNumber}
                          helperText={
                            !ccErrors.cardNumber
                              ? ""
                              : ccErrors.cardNumber.message
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment style={{ color: "#757575" }}>
                                <LockIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    </InputMask>
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <div>
                  <span>Expiration</span>
                </div>
                <Controller
                  name="expiryDate"
                  control={ccControl}
                  rules={{ required: "Required" }}
                  as={
                    <InputMask mask="99/99">
                      {(inputProps) => (
                        <TextField
                          className={localClasses.noMargin}
                          {...inputProps}
                          variant="outlined"
                          fullWidth
                          margin="dense"
                          label="MM/YY"
                          error={!!ccErrors.expiryDate}
                          helperText={
                            !ccErrors.expiryDate
                              ? ""
                              : ccErrors.expiryDate.message
                          }
                        />
                      )}
                    </InputMask>
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <div>
                  <span>Name on Card</span>
                </div>
                <TextField
                  className={localClasses.noMargin}
                  variant="outlined"
                  // required
                  fullWidth
                  margin="dense"
                  name="nameOnCard"
                  inputRef={registerCC({
                    required: "Required",
                    validate: (value) =>
                      value.trim().length < 1 ? "Required" : null,
                  })}
                  error={!!ccErrors.nameOnCard}
                  helperText={
                    !ccErrors.nameOnCard ? "" : ccErrors.nameOnCard.message
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <div>
                  <span>CVV</span>
                </div>
                <TextField
                  className={localClasses.noMargin}
                  variant="outlined"
                  // required
                  fullWidth
                  margin="dense"
                  name="cardCode"
                  inputRef={registerCC({
                    required: "Required",
                    validate: (value) => {
                      if (value.trim().length < 3 || value.trim().length > 4)
                        return "Invalid CVV";
                    },
                  })}
                  error={!!ccErrors.cardCode}
                  helperText={
                    !ccErrors.cardCode ? "" : ccErrors.cardCode.message
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment style={{ color: "#757575" }}>
                        <LockIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              className={localClasses.formButtonsContainer}
            >
              <Grid item xs={8}>
                <Button
                  fullWidth
                  variant="contained"
                  disableelevation="true"
                  style={{ backgroundColor: "#276A95", color: "white" }}
                  type="submit"
                  disabled={isUpdatingCardInfo}
                >
                  {!isUpdatingCardInfo ? (
                    "Save"
                  ) : (
                    <CircularProgress size="24px" color="white" />
                  )}
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  fullWidth
                  variant="outlined"
                  disableelevation="true"
                  onClick={() => {
                    window.location = "/manageflexpay";
                  }}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    </div>
  );
}
