import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import wilcomStyles from "../theme/WilcomStyles";

const useStyles = makeStyles(wilcomStyles);

export default function SignInModalRightBar({ imageUrl, bulletPoints, reviewComponent, width }) {
  const classes = useStyles();

  const bulletPointListItems = bulletPoints.map((bulletPoint) => <li key={bulletPoint}>{bulletPoint}</li>);

  return (
    <div style={{ width }} className={classes.signInRightBarWrapper}>
      <div>
        <img src={imageUrl} alt="Embroidery design" height="330" />
      </div>
      <div style={{ padding: "0 14px" }}>
        <ul className={classes.signInList} >
          {bulletPointListItems}
        </ul>
        <hr style={{ borderTop: "2px solid #CECECE" }}></hr>
        {reviewComponent}
      </div>
    </div>
  );
}

SignInModalRightBar.defaultProps = {
  width: "426px",
}