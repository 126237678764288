import React, { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import { ErrorContext } from "../reducer/errorReducer";

import { v4 } from "uuid";
import { Auth } from "aws-amplify";

import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import InputAdornment from '@material-ui/core/InputAdornment';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import Cookies from "js-cookie";
import countryList from "country-list";

import { getUserGeo, checkSync, validateAccountKey } from "../lib/userLib";

import ConfirmSignUp from "../components/ConfirmSignUp";
import ExistingUserDialogue from "../components/ExistingUserDialogue";
import LoadingButton from "../components/LoadingButton";
import CustomerReview from "../components/CustomerReview";
import SignInModalRightBar from "../components/SignInModalRightBar";
import SignUpDiscoveryMenu from "../components/SignUpDiscoveryMenu";
import HeaderLogoSnackBar from "../components/HeaderLogoSnackBar";
import ShowPasswordAdornment from "../components/ShowPasswordAdornment";

import { REFERRER_CODE } from "../lib/referralLib";
import wilcomStyles from "../theme/WilcomStyles";
import { Typography, Link } from "@material-ui/core";
import { themeConfig } from "../config/theme-config";

const { IsNotProd } = themeConfig;
const useStyles = makeStyles(wilcomStyles);

const countries = countryList.getData().sort(function (a, b) {
  const countryA = a.name.toLowerCase();
  const countryB = b.name.toLowerCase();
  if (countryA < countryB) {
    return -1;
  }
  if (countryA > countryB) {
    return 1;
  }
  return 0;
});
let eventData = null;

// Move to library
const signUpErrorCodes = {
  INVALID_PARAM: "InvalidParameterException",
  USERNAME_EXISTS: "UsernameExistsException",
  INVALID_PASSWORD: "InvalidPasswordException",
  LAMBDA_VALIDATION_ERROR: "UserLambdaValidationException",
}

const CAMPAIGN_ID_COOKIE_KEY = "CAMPAIGN_ID"
const CAMPAIGN_ID_QUERY_PARAM_NAME = "campaign";

export default function SignUp(props) {
  const classes = useStyles();

  const { showMessage, dispatch, state } = useContext(ErrorContext);
  const { show } = state;

  const {
    errors,
    register,
    unregister,
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearError,
  } = useForm();

  const [showConfirmUser, setShowConfirmUser] = useState(false);
  const [generatedUsername] = useState(v4());
  const [isLoading, setIsLoading] = useState(false);
  const [existingUserEmail, setExistingUserEmail] = useState("");
  const [haveGeo, setHaveGeo] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("AU");
  const [showExistingUserDialogue, setShowExistingUserDialogue] = useState(false);
  const [countryDropdownOpen, setCountryDropdownOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    if (!!IsNotProd) {
      register({ name: "accountKey" }, { required: "Account key required" });
    }

    register(
      { name: "email" },
      {
        required: "Email address required",
        maxLength: {
          value: 100,
          message: "Email must be less than 100 characters",
        },
        validate: (value) => {
          // Regex from http://emailregex.com/
          if (
            !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g.test(
              value
            )
          ) {
            return "Invalid email address";
          } else if (/[A-Z]/g.test(value)) {
            return "Email address should be entered in all lowercase";
          }
        },
      }
    );
    register(
      { name: "password" },
      {
        required:
          "The password must have at least 8 characters with both uppercase and lowercase letters and a number",
        validate: (value) => {
          if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,40}$)/.test(value))
            return null;
          if (
            value.length > 40 &&
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,}$)/.test(value)
          )
            return "The password must have less than 40 characters";
          if (
            value.length > 40 &&
            !/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,}$)/.test(value)
          )
            return "The password must have less than 40 characters; with both uppercase and lowercase letters and a number";
          if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/.test(value))
            return "The password must have at least 8 characters with both uppercase and lowercase letters and a number";
        },
      }
    );
    register(
      { name: "passwordConfirm" },
      {
        required: "Must confirm password",
        validate: (value) => {
          return value === getValues().password
            ? null
            : "Passwords do not match";
        },
      }
    );
    register(
      { name: "firstName" },
      {
        required: "First name required",
        maxLength: {
          value: 50,
          message: "First name up to 50 characters only",
        },
        validate: (value) => {
          if (/^ +$/.test(value)) return "First name required";
          if (!/^[a-zA-Z àâäôéèëêïîçùûüÿæœÀÂÄÔÉÈËÊÏÎŸÇÙÛÜÆŒ]+$/.test(value)) return "Invalid first name"; // Letters only
          if (value.toLowerCase() === "null") return "Invalid first name";
        },
      }
    );
    register(
      { name: "lastName" },
      {
        required: "Last name required",
        maxLength: {
          value: 50,
          message: "Last name up to 50 characters only",
        },
        validate: (value) => {
          if (/^ +$/.test(value)) return "Last name required";
          if (!/^[a-zA-Z àâäôéèëêïîçùûüÿæœÀÂÄÔÉÈËÊÏÎŸÇÙÛÜÆŒ]+$/.test(value)) return "Invalid last name";
          if (value.toLowerCase() === "null") return "Invalid last name";
        },
      }
    );
    register({ name: "country" }, { required: "Please select country/region" });
    register({ name: "discovery" }, { required: "Please select an option" });
    register(
      { name: "terms" },
      { validate: (value) => value || "Tick above to continue" }
    );
    // dismount: clean register
    return function () {
      unregister([
        "eamil",
        "password",
        "passwordConfirm",
        "firstName",
        "lastName",
        "country",
        "discovery",
        "terms",
      ]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register, unregister]);

  // useEffect for clean timer when dismount;
  useEffect(() => {
    return () => {
      if (window.timer) clearTimeout(window.timer);
      dispatch({ type: "hide" });
    };
  }, [dispatch]);

  if (!haveGeo) {
    getUserGeo()
      .then((geo) => {
        console.log({ geo });
        setHaveGeo(true);
        setValue("country", geo.country);
        setSelectedCountry(geo.country);
      })
      .catch((err) => {
        showMessage(err.message, "error");
        console.log(err, err.stack);
        setIsLoading(false);
      });
  }
  function handleChange(errors, e) {
    if (errors[e.target.name]) {
      clearError(e.target.name);
    }
    if (e.target.name === "country") {
      handleCountryChange(e);
      return;
    }
    setValue(
      e.target.name,
      e.target.name !== "terms" ? e.target.value : e.target.checked
    );
  }

  // wish to be refactor completely via react hook form way instead of mixed approach
  function handleCountryChange(event) {
    setValue("country", event.target.value);
    setSelectedCountry(event.target.value);
  }

  function handleDiscoveryChange(discoveryValue) {
    console.log({ discoveryValue });
    setValue("discovery", discoveryValue);
  }

  function confirmationSuccessful(success) {
    if (success) {
      showMessage(
        `Registration successful! For security reasons, you will be asked to sign in to continue.`,
        "success"
      );
      setTimeout(() => (window.location = "/signin"), 3000);
    }
  }

  function onCloseExistingUserDialogue(result) {
    console.log({ onCloseExistingUserDialogue: result });
    setShowExistingUserDialogue(false);
    if (result) {
      console.log({ onCloseExistingUserDialogue: "submitSignUp" });
      submitSignUp();
    }
  }

  function mapForm(formData) {
    let mappedData = {
      password: formData.get("password").trim(),
      name: formData.get("firstName").trim(),
      family_name: formData.get("lastName").trim(),
      email: formData.get("email").trim(),
      phone_number: (formData.get("phone") || "").trim(),
      country: formData.get("country").trim(),
      discovery: formData.get("discovery").trim(),
      terms: !!formData.get("terms") || false,
    };
    if (!!IsNotProd) {
      mappedData.accountKey = formData.get("accountKey").trim();
    }
    return mappedData;
  }

  async function onSubmit(data, event) {
    console.log(data, "fired");
    event.preventDefault();

    eventData = mapForm(new FormData(event.target));
    console.log({ eventData });

    setIsLoading(true);

    setExistingUserEmail(eventData.email);

    try {
      const checkSyncResponse = await checkSync({ email: eventData.email, username: generatedUsername });
      console.log({ checkSyncResponse });

      const { checkResponse } = checkSyncResponse;
      if (!!checkResponse.status && checkResponse.status === "unconfirmed") {
        setError("email", `emailerror`, "Email already in use.");
        setIsLoading(false);
        return;
      }
      if (!!checkResponse.field) {
        const { field, message } = checkResponse;
        setError(field, `${field}error`, message);
        setIsLoading(false);
        return;
      }

      if (checkResponse.userExists) {
        setIsLoading(false);
        setShowExistingUserDialogue(true);
        return;
      }

      if (!!IsNotProd) {
        try {
          // Verifying account key
          const accountKeyResponse = await validateAccountKey({
            email: eventData.email,
            accountKey: eventData.accountKey,
          });
          console.log({ accountKeyResponse });
        } catch (accountKeyError) {
          console.log({ accountKeyError });
          setIsLoading(false);
          setError("accountKey", accountKeyError.code, "Invalid Account key");
          return;
        }
      }
      submitSignUp();
    } catch (checkSyncError) {
      console.error({ checkSyncError });
      showMessage(checkSyncError.message, "error");
      setIsLoading(false);
    }
  };

  async function submitSignUp() {
    console.log({ eventData });
    if (!eventData) {
      showMessage("Please complete the Sign Up form.", "error");
      return;
    }
    const {
      name,
      family_name,
      email,
      phone_number,
      password,
      country,
      discovery,
    } = eventData;

    console.log({ eventData });

    if (!password || !email) {
      console.log("formadata is empty");
      return;
    }

    // Fetch campaign ID
    const campaignId = 
      new URLSearchParams(window.location.search).get(CAMPAIGN_ID_QUERY_PARAM_NAME) || 
      Cookies.get(CAMPAIGN_ID_COOKIE_KEY);
    const gaClientId = !!Cookies.get("_ga") ? Cookies.get("_ga").slice(6) : null; // Remove GA1.1. prefix
    console.log({ campaignId, gaClientId });

    setIsLoading(true);
    const newUserData = {
      username: generatedUsername,
      password,
      attributes: {
        email,
        phone_number,
        name,
        family_name,
        "custom:dnn_temp_signup_info": JSON.stringify({
          auth_password: password,
          country,
          discovery,
          region: "none",
          referrer: Cookies.get(REFERRER_CODE),
          campaign_id: campaignId || null,
          ga_client_id: gaClientId
        }),
      },
    };
    console.log({ newUserData });

    try {
      const signUpData = await Auth.signUp(newUserData);
      console.log({ signUpData });

      if (!signUpData.userConfirmed) {
        setShowConfirmUser(true);
        setIsLoading(false);
      } else {
        try {
          await Auth.signIn(email, password);
        } catch (signInError) {
          console.error({ signInError });
        }
        return;
      }
    } catch (signUpError) {
      console.error({ signUpError });

      switch (signUpError.code) {
        case signUpErrorCodes.INVALID_PASSWORD:
          setError("password", signUpError.code, signUpError.message);
          break;
        case signUpErrorCodes.LAMBDA_VALIDATION_ERROR:
          showMessage(
            signUpError.message.replace("PreSignUp failed with error", ""),
            "error"
          );
          break;
        default:
          showMessage(signUpError.message, "error");
          break;
      }
    }

    setIsLoading(false);
  }

  const confirmationDialogue = (
    <ConfirmSignUp
      username={generatedUsername}
      setShowConfirmUser={setShowConfirmUser}
      confirmationSuccessful={confirmationSuccessful}
    />
  );


  const countryChevron = countryDropdownOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />;
  const snackbarWrapperStyle = {
    paddingBottom: show ? 44 : 0
  };

  return showConfirmUser ? (
    confirmationDialogue
  ) : (
    <Container
      component="main"
      className={`${classes.mainContainer} ${classes.signUpContainer}`}
      maxWidth="xs"
      id="signUpContainer"
    >
      <CssBaseline />
      <div className={classes.signUpFormWrapper} >
        <div
          className={`${classes.paper} ${classes.mainSignUpForm}`}
        >
          <div
            style={snackbarWrapperStyle}
            className={classes.snackbarWrapper}
          >
            <HeaderLogoSnackBar
              customClass={classes.snackbarCustomClass}
              showCloseButton={false}
            />
          </div>

          <Typography
            color="primary"
            component="h1"
            className={`${classes.formHeading} ${classes.signUpMainHeading}`}
          >
            Register now to get started
          </Typography>
          <Typography
            color="primary"
            component="h3"
            className={`${classes.formHeading} ${classes.signUpSubHeading}`}
          >
            Your pathway to embroidery digitizing fun!
          </Typography>

          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={errors.hasOwnProperty("firstName")}
                  autoComplete="fname"
                  name="firstName"
                  variant="outlined"
                  fullWidth
                  id="firstName"
                  onChange={(e) => handleChange(errors, e)}
                  label="First Name"
                />
                {errors.firstName && (
                  <p id="firstNameError" className={classes.signUpError}>
                    {" "}
                    {"* " + errors.firstName.message}{" "}
                  </p>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={errors.hasOwnProperty("lastName")}
                  variant="outlined"
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  onChange={(e) => handleChange(errors, e)}
                  autoComplete="lname"
                />
                {errors.lastName && (
                  <p id="lastNameError" className={classes.signUpError}>
                    {" "}
                    {"* " + errors.lastName.message}{" "}
                  </p>
                )}
              </Grid>

              {!!IsNotProd && (
                <Grid item xs={12}>
                  <TextField
                    error={errors.hasOwnProperty("accountKey")}
                    variant="outlined"
                    fullWidth
                    id="accountKey"
                    label="Account Key"
                    name="accountKey"
                    autoComplete="off"
                    onChange={(e) => handleChange(errors, e)}
                    autoFocus
                  />
                  {errors.accountKey && (
                    <p id="accountKeyError" className={classes.signUpError}>
                      {" "}
                      {"* " + errors.accountKey.message}{" "}
                    </p>
                  )}
                </Grid>
              )}

              <Grid item xs={12}>
                <TextField
                  error={errors.hasOwnProperty("email")}
                  variant="outlined"
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  onChange={(e) => handleChange(errors, e)}

                />
                {errors.email && (
                  <p id="emailError" className={classes.signUpError}>
                    {" "}
                    {"* " + errors.email.message}{" "}
                  </p>
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  error={errors.hasOwnProperty("password")}
                  variant="outlined"
                  fullWidth
                  name="password"
                  label="Password"
                  type={!showPassword ? "password" : "text"}
                  id="password"
                  className={classes.removeInputAdornmentRightPadding}
                  onChange={(e) => handleChange(errors, e)}
                  autoComplete="current-password"
                  InputProps={{
                    endAdornment:
                      <ShowPasswordAdornment
                        showPassword={showPassword}
                        setShowPassword={setShowPassword}
                      />
                  }}
                />
                {errors.password && (
                  <p id="passwordError" className={classes.signUpError}>
                    {" "}
                    {"* " + errors.password.message}{" "}
                  </p>
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  error={errors.hasOwnProperty("passwordConfirm")}
                  variant="outlined"
                  fullWidth
                  name="passwordConfirm"
                  label="Confirm Password"
                  type={!showConfirmPassword ? "password" : "text"}
                  id="passwordConfirm"
                  className={classes.removeInputAdornmentRightPadding}
                  onChange={(e) => handleChange(errors, e)}
                  InputProps={{
                    endAdornment:
                      <ShowPasswordAdornment
                        showPassword={showConfirmPassword}
                        setShowPassword={setShowConfirmPassword}
                      />
                  }}
                />
                {errors.passwordConfirm && (
                  <p id="passwordConfirmError" className={classes.signUpError}>
                    {" "}
                    {"* " + errors.passwordConfirm.message}{" "}
                  </p>
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  error={errors.hasOwnProperty("country")}
                  className={classes.formControl}
                >
                  <InputLabel htmlFor="outlined-country">Country</InputLabel>
                  <Select
                    value={selectedCountry}
                    onChange={(e) => handleChange(errors, e)}
                    onOpen={() => setCountryDropdownOpen(true)}
                    onClose={() => setCountryDropdownOpen(false)}
                    defaultValue={"AU"}
                    displayEmpty
                    IconComponent={(props) => (
                      <InputAdornment
                        position="start"
                        className={classes.signUpCountryChevron}
                      >
                        {countryChevron}
                      </InputAdornment>)}
                    input={
                      <OutlinedInput
                        labelWidth={60}
                        name="country"
                        id="outlined-country"
                      />
                    }
                  >
                    {countries.map((country) => (
                      <MenuItem key={country.code} value={country.code}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {errors.country && (
                  <p id="countryError" className={classes.signUpError}>
                    {" "}
                    {"* " + errors.country.message}{" "}
                  </p>
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.formControl}
                >
                  <SignUpDiscoveryMenu
                    error={errors.hasOwnProperty("discovery")}
                    onChange={handleDiscoveryChange}
                  />
                </FormControl>

                {errors.discovery && (
                  <p id="levelError" className={classes.signUpError}>
                    {" "}
                    {"* " + errors.discovery.message}{" "}
                  </p>
                )}
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value="true"
                      color="primary"
                      name="terms"
                      id="terms"
                      onChange={(e) => handleChange(errors, e)}
                    />
                  }
                  label={
                    <Typography
                      className={classes.termsAndConditionsLabel}
                      variant="body2"
                      align="center"
                    >
                      I agree to the terms and conditions below*
                    </Typography>
                  }
                />
                {errors.terms && (
                  <p id="termsError" className={classes.signUpError}>
                    {" "}
                    {"* " + errors.terms.message}{" "}
                  </p>
                )}
              </Grid>

              <Grid item xs={12}>
                <Typography variant="caption" className={classes.termsAndConditions}>
                  {`*Terms & Conditions: By registering, I agree that Wilcom and their official partners may send me important information regarding products, services and exclusive offers. I can opt out at any time. Learn more: `}
                  <Link
                    style={{ textDecoration: "underline" }}
                    href="https://hatchembroidery.com/privacy-policy/"
                    target="_blank"
                  >
                    Privacy Policy.
                  </Link>
                </Typography>
              </Grid>

              <LoadingButton
                type="submit"
                id="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.signUpButton}
                buttonText="Register"
                isLoading={isLoading}
              />
            </Grid>
          </form>
        </div>
        <SignInModalRightBar
          width="325px"
          imageUrl="/sign-up-image.png"
          bulletPoints={[
            "Full working 30-Day FREE Trial",
            "Over 200 FREE online Academy lessons from our experts",
            "Access to over 400 FREE designs"
          ]}
          reviewComponent={
            <CustomerReview
              reviewContent={`“User friendly, easy to understand, loads of tuition videos, excellent support…”`}
              reviewerName="Ann Williams"
              reviewerTitle="Hobby Embroiderer"
              imageUrl="/reviewer-photo-2.png"
              starsImageUrl="/5-stars.svg"
            />
          }
        />
      </div>
      <ExistingUserDialogue
        show={showExistingUserDialogue}
        existingUserEmail={existingUserEmail}
        onResponse={onCloseExistingUserDialogue}
      />
    </Container>
  );
}
