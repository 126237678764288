import { API, Auth } from "aws-amplify";
import { cognitoApiDefautHeaders } from "../lib/authLib";
import { secretIframeAsync } from "../lib/iframeLoadAsync";
import { hostRedirect } from "../config/sso-config";
const COMMUMITY_LOCATION = "https://cheesecakes.mywilcom.org/";

export async function storeSignIn(returnUrl) {
  const apiName = "wilcomIdApi";
  const path = "/store/jwt";
  const myInit = {
    headers: cognitoApiDefautHeaders(await Auth.currentSession()),
    body: { returnUrl },
  };
  try {
    const resp = await API.post(apiName, path, myInit);
    console.log(resp);
    window.location = resp.signInUrl;
  } catch (err) {
    console.log(err, err.stack);
    throw err;
  }
}

export async function academy3SignIn(returnUrl) {
  const apiName = "wilcomIdApi";
  const path = "/academy3/jwt";
  const myInit = {
    headers: cognitoApiDefautHeaders(await Auth.currentSession()),
    body: { returnUrl },
  };
  try {
    const resp = await API.post(apiName, path, myInit);
    console.log(resp);
    window.location = resp.signInUrl;
  } catch (err) {
    console.log(err, err.stack);
    throw err;
  }
}

export async function blogSignIn(returnUrl) {
  const apiName = "wilcomIdApi";
  const path = "/blog/jwt";
  const myInit = {
    headers: cognitoApiDefautHeaders(await Auth.currentSession()),
    body: { returnUrl },
  };
  try {
    const resp = await API.post(apiName, path, myInit);
    console.log(resp);
    window.location = resp.signInUrl;
  } catch (err) {
    console.log(err, err.stack);
    throw err;
  }
}

export async function communitySignIn(returnUrl) {
  console.log("TODO!!!! COMMUMITY_LOCATION");
  window.location = COMMUMITY_LOCATION;
}

export async function redirectSignIn(ssoRedirect) {
  console.log({ ssoRedirect });
  const ssoCfg = hostRedirect[ssoRedirect.referrerHost];
  console.log({ ssoCfg });

  if (!ssoCfg) {
    window.location = `${ssoRedirect.returnUrl}`;
    return;
  }
  if (ssoCfg.isStore) {
    storeSignIn(ssoRedirect.returnUrl);
    return;
  }
  if (ssoCfg.isAcademy3) {
    academy3SignIn(ssoRedirect.returnUrl);
    return;
  }
  if (ssoCfg.isBlog) {
    blogSignIn(ssoRedirect.returnUrl);
    return;
  }
  if (ssoCfg.isCommunity) {
    // storeSignIn(ssoCfg.returnUrl);
    // return;
  }
}

export async function secretServiceLogin() {
  const apiName = "wilcomIdApi";
  const storeApiPath = "/store/jwt";
  const academy3ApiPath = "/academy3/jwt";
  const blogApiPath = "/blog/jwt";
  const myInit = {
    headers: cognitoApiDefautHeaders(await Auth.currentSession()),
  };
  try {
    console.log("signing in");
    const responses = await Promise.allSettled([
      API.post(apiName, storeApiPath, myInit),
      API.post(apiName, academy3ApiPath, myInit),
      API.post(apiName, blogApiPath, myInit),
    ]);
    console.log({ responses });
    const fulfilledPromises = responses.filter((response) => response.status === "fulfilled");
    console.log({ fulfilledPromises });
    const signInTokenUrls = fulfilledPromises.map((data) => data.value.signInUrl);
    console.log({ signInTokenUrls });
    return await secretIframeAsync(signInTokenUrls);
  } catch (err) {
    console.log({ err }, err.stack);
  }
}
