import React, { useContext, useState } from "react";
import { ErrorContext } from "../reducer/errorReducer";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Grid, TextField, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import HatchHeaderLogo from "../components/HatchHeaderLogo";
import isAuthorized from "../components/IsAuthorized";
import WilcomIdAppBar from "../components/WilcomIdAppBar";
import useSignInStatus from "../hooks/useSignInStatus";
import wilcomStyles from "../theme/WilcomStyles";
import { Auth } from "aws-amplify";
import { initiateEmailChangeAsync, confirmUserEmailChangeAsync } from "../lib/userLib";
import LoadingButton from "../components/LoadingButton";
const useStyles = makeStyles(wilcomStyles);

const viewingModes = {
  verifyInitial: "verifyInitial",
  enterNewEmail: "enterNewEmail",
  verifyFinal: "verifyConfirm",
};

const SUBMIT_TICKET_LINK = process.env.REACT_APP_SUBMIT_TICKET_LINK || "https://help.hatchembroidery.com/portal/en/newticket?departmentId=462391000001427029&layoutId=462391000001432316";

export default function ChangeEmail(props) {
  const classes = useStyles();
  const { isSignedIn, userName } = useSignInStatus();
  const { showMessage } = useContext(ErrorContext);

  const [viewing, setViewing] = useState(viewingModes.verifyInitial);
  const [ isLoading, setIsLoading ] = useState(false);


  const handleInitialVerification = async (code) => {
    try {
      setIsLoading(true);
      const initialVerificationResult =
        await Auth.verifyCurrentUserAttributeSubmit("email", code.trim());
      console.log({ initialVerificationResult });
      showMessage("Your identity has been verified.", "success");
      setViewing(viewingModes.enterNewEmail);
    } catch (initialVerificationErr) {
      console.error({ initialVerificationErr });
      showMessage(initialVerificationErr.message, "error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleInitiateEmailChange = async (newEmail) => {
    console.log({ newEmail });
    try {
      setIsLoading(true);
      const initiateEmailChangeResponse = await initiateEmailChangeAsync(newEmail.toLowerCase().trim());
      console.log({ initiateEmailChangeResponse });
      setViewing(viewingModes.verifyFinal);
    } catch (initiateEmailChangeErr) {
      console.error({ initiateEmailChangeErr });
      showMessage(initiateEmailChangeErr?.response?.data?.message, "error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirmEmailChange = async (code) => {
    try {
      setIsLoading(true);
      const confirmEmailChangeResult = await confirmUserEmailChangeAsync(code.trim());
      console.log({ confirmEmailChangeResult });
      showMessage("Your email has been changed successfully.", "success");
      setTimeout(() => {
        window.location = "/profile";
      }, 2000);
    } catch (confirmEmailChangeErr) {
      console.error({ confirmEmailChangeErr });
      showMessage(confirmEmailChangeErr?.response?.data?.message, "error");
    } finally {
      setIsLoading(false);
    }
  };

  const authorizedComponent = (
    <div>
      <WilcomIdAppBar isSignedIn={isSignedIn} userName={userName} />
      <Container
        component="main"
        className={classes.mainContainer}
        maxWidth="sm"
      >
        <CssBaseline />
        <div className={classes.paper}>
          <HatchHeaderLogo />
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Link to="/profile" style={{ textDecoration: "none" }}>
                  <Button variant="text">
                    <ArrowBackIcon /> &nbsp; &nbsp;
                    <span>Back</span>
                  </Button>
                </Link>
              </div>
            </Grid>
            {viewing === viewingModes.verifyInitial && (
              <ChangeEmailVerifyInitial
                onVerify={handleInitialVerification}
              />
            )}
            {viewing === viewingModes.enterNewEmail && (
              <ChangeEmailEnterNew
                onSubmit={handleInitiateEmailChange}
                buttonIsLoading={isLoading}
              />
            )}
            {viewing === viewingModes.verifyFinal && (
              <ChangeEmailVerifyFinal
                onConfirm={handleConfirmEmailChange}
                buttonIsLoading={isLoading}
              />
            )}
            <Grid item xs={3}>
              <Link to="/profile" style={{ textDecoration: "none" }}>
                <Button fullWidth variant="outlined" color="primary">
                  Cancel
                </Button>
              </Link>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );

  return isAuthorized({ authorizedComponent, props });
}

function ChangeEmailVerifyInitial(props) {
  const { onVerify } = props;
  const { register, errors, getValues } = useForm({ mode: "onChange" });

  return (
    <>
      {" "}
      <Grid item xs={12}>
        <h3>Enter the confirmation code sent to your <b>CURRENT</b> email</h3>
      </Grid>
      <Grid item xs={12}>
        An email has been sent to your email to verify your identity. Check your
        inbox and enter the received code below.
      </Grid>
      <Grid item xs={12} style={{ marginTop: 25, marginBottom: 25 }}>
        <TextField
          variant="outlined"
          // required
          fullWidth
          margin="dense"
          id="verificationCode"
          label="Enter the confirmation code"
          name="verificationCode"
          inputRef={register({
            required: "Verification code is required",
          })}
          error={!!errors.verificationCode}
          helperText={
            !errors.verificationCode
              ? null
              : `*${errors.verificationCode.message}`
          }
        />
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 20 }}>
        Don't have access to the current email address? 
        <br/>
        Raise a ticket to Technical Support <a href={SUBMIT_TICKET_LINK}> here</a>
      </Grid>
      <Grid item xs={3}>
        <Button
          fullWidth
          variant="contained"
          onClick={() => {
            onVerify(getValues().verificationCode);
          }}
          color="primary"
          disabled={!getValues().verificationCode || !!errors.verificationCode}
        >
          Next
        </Button>
      </Grid>
    </>
  );
}

function ChangeEmailEnterNew(props) {
  const { onSubmit, buttonIsLoading } = props;
  const { register, errors, getValues } = useForm({ mode: "onChange" });

  return (
    <>
      {" "}
      <Grid item xs={12}>
        <h3>Enter your new email address</h3>
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          // required
          fullWidth
          margin="dense"
          id="newEmail"
          label="Enter your new email address"
          name="newEmail"
          inputRef={register({
            required: "A new email address is required",
            pattern: {
              value:
                /^\s*(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/,
              message: "Email must be valid",
            },
          })}
          error={!!errors.newEmail}
          helperText={!errors.newEmail ? null : `*${errors.newEmail.message}`}
        />
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 25 }}>
        <TextField
          variant="outlined"
          // required
          fullWidth
          margin="dense"
          id="confirmEmail"
          label="Confirm email address"
          name="confirmEmail"
          inputRef={register({
            required: "Confirm email required",
            validate: value => value === getValues().newEmail || "Emails must match",
          })}
          error={!!errors.confirmEmail}
          helperText={!errors.confirmEmail ? null : `*${errors.confirmEmail.message}`}
        />
      </Grid>
      <Grid item xs={3}>
          <LoadingButton
            fullWidth
            variant="contained"
            color="primary"
            buttonText="Save"
            isLoading={buttonIsLoading}
            onClick={async () => {
              onSubmit(getValues().newEmail);
            }}
            disabled={!getValues().newEmail || !!errors.newEmail || !getValues().confirmEmail || !!errors.confirmEmail}
          />
      </Grid>
    </>
  );
}

function ChangeEmailVerifyFinal(props) {
  const { onConfirm, buttonIsLoading } = props;
  const { register, errors, getValues } = useForm({ mode: "onChange" });

  return (
    <>
      {" "}
      <Grid item xs={12}>
        <h3>Enter the confirmation code sent to your <b>NEW</b> email</h3>
      </Grid>
      <Grid item xs={12}>
        An email has been sent to your new email address to verify the email.
        Check your inbox and enter the received code below.
      </Grid>
      <Grid item xs={12} style={{ marginTop: 25, marginBottom: 25 }}>
        <TextField
          variant="outlined"
          // required
          fullWidth
          margin="dense"
          id="confirmVerificationCode"
          label="Enter the confirmation code"
          name="confirmVerificationCode"
          inputRef={register({
            required: "Verification code is required",
          })}
          error={!!errors.confirmVerificationCode}
          helperText={
            !errors.confirmVerificationCode
              ? null
              : `*${errors.confirmVerificationCode.message}`
          }
        />
      </Grid>
      <Grid item xs={3}>
        <LoadingButton
          fullWidth
          variant="contained"
          buttonText="Confirm"          
          onClick={() => {
            onConfirm(getValues().confirmVerificationCode);
          }}
          color="primary"
          isLoading={buttonIsLoading}
          disabled={
            !getValues().confirmVerificationCode ||
            !!errors.confirmVerificationCode
          }
        />
      </Grid>
    </>
  );
}
