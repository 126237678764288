import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "formdata-polyfill";

import React from "react";
import ReactDOM from "react-dom";

import Amplify, { API } from "aws-amplify";
import awsConfig from "./config/awsConfigProvider";
import { autoRedirect } from "./lib/redirectLib";
import { captureReferral } from "./lib/referralLib";
import { suppressLogs } from "./lib/loggingLib";

import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

suppressLogs();

Amplify.configure(awsConfig);
API.configure(awsConfig.API); //bug mitigation

captureReferral().then(() => autoRedirect());

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
