import React, { useState, useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Checkbox,
  Grid,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  TextField,
  FormControl,
  FormControlLabel,
  Typography,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LoadingButton from "../components/LoadingButton";
import { updateUserProfileAsync } from "../lib/userLib";
import wilcomStyles from "../theme/WilcomStyles";
import countryList from "country-list";
import { Auth } from "aws-amplify";
import { ErrorContext } from "../reducer/errorReducer";
import { checkHasFlexpayAsync } from "../lib/flexpayApiLib";

const useStyles = makeStyles(wilcomStyles);
const countries = countryList.getData();

export default function ProfileInfo(props) {
  const classes = useStyles();
  const {
    userData,
    extendedUserData,
    onComplete,
    onNotify,
    onFinally
  } = props;
  console.log({ extendedUserData });
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(
    `${extendedUserData.country}`
  );
  const [selectedLevel, setSelectedLevel] = useState(
    `${extendedUserData.user_level}`
  );
  const [receiveMarketingEmail, setReceiveMarketingEmail] = useState(
    // eslint-disable-next-line eqeqeq
    extendedUserData.marketing_email
  );
  const [hasUsedFlexpay, setHasUsedFlexpay] = useState(false);
  const [flexpayManagementUrl, setFlexpayManagementUrl] = useState("");
  const { dispatch, showMessage } = useContext(ErrorContext);
  const { register, errors, handleSubmit } = useForm();

  useEffect(() => {
    (async () => {
      try {
        const checkFlexpayResponse = await checkHasFlexpayAsync();
        console.log({ checkFlexpayResponse });
        setHasUsedFlexpay(checkFlexpayResponse.hasFlexpayAccount);
        if(checkFlexpayResponse.flexpayPlatform === "dnn") {
          setFlexpayManagementUrl(process.env.REACT_APP_UPDATE_FLEXPAY_DETAILS_URL);
        } else {
          setFlexpayManagementUrl(`${window.location.origin}/manageflexpay`);
        }
      } catch (checkFlexpayErr) {
        console.error({ checkFlexpayErr });
      }
    })();
    
    return () => {
      if (window.timer) clearTimeout(window.timer);
      dispatch({ type: "hide" });
    };
  }, [dispatch]);

  console.log({ selectedCountry, selectedLevel, receiveMarketingEmail });
  function handleCountryChange(event) {
    setSelectedCountry(event.target.value);
  }
  function handleLevelChange(event) {
    setSelectedLevel(event.target.value);
  }

  function handleSaveProfile(data, event) {
    event.preventDefault();
    console.log(errors, "errors");
    const formData = new FormData(event.target);
    const name = formData.get("firstName");
    const family_name = formData.get("familyName");
    const newUserData = {
      name,
      family_name
    };

    const country = formData.get("country");
    const level = formData.get("level");
    const newExtendedUserData = {
      user_level: level,
      country: `${country}`,
      marketing_email: receiveMarketingEmail
    };
    console.log({ newExtendedUserData });
    setIsLoading(true);
    console.log({ newUserData, newExtendedUserData });
    updateUserProfileAsync(newUserData, newExtendedUserData)
      .then(onComplete)
      .catch(onNotify)
      .finally(data => {
        setIsLoading(false);
        onFinally(data);
      });
  }

  function handleChangePassword() {
    Auth.signOut()
      .then(data => {
        console.log(data);
        window.location = "/resetpassword";
      })
      .catch(err => showMessage(err.message, "error"));
  }

  async function initiateEmailChange() {
    const user = await Auth.currentAuthenticatedUser();
    console.log({ user });
    try {
      await Auth.verifyUserAttribute(user, "email", {});
      window.location = "/changeemail"
    } catch (initiateEmailChangeErr) {
      console.log({ initiateEmailChangeErr });
      showMessage(initiateEmailChangeErr.message, "error");
    }
  }

  const profileEdit = (
    <form className={classes.form} onSubmit={handleSubmit(handleSaveProfile)}>
      <Grid container spacing={1}>
        <Grid item xs={8}>
          <TextField
            variant="outlined"
            // required
            fullWidth
            margin="dense"
            id="email"
            label="Email Address"
            name="email"
            disabled
            inputRef={register({ required: "Email required" })}
            value={userData.email}
          />
          {errors.email && (
            <p className={classes.signUpError}>
              {" "}
              {"* " + errors.email.message}{" "}
            </p>
          )}
        </Grid>
        <Grid item xs={4} container alignItems="center">
          <Button 
            fullWidth
            variant="outlined"
            onClick={() => { initiateEmailChange() }}
            color="secondary"
            >
            Change email
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            autoComplete="fname"
            name="firstName"
            variant="outlined"
            // required
            fullWidth
            margin="dense"
            id="firstName"
            label="First Name"
            inputRef={register({ required: "First name required" })}
            defaultValue={userData.firstName}
          />
          {errors.firstName && (
            <p id="firstNameError" className={classes.signUpError}>
              {" "}
              {"* " + errors.firstName.message}{" "}
            </p>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            // required
            fullWidth
            margin="dense"
            id="familyName"
            label="Last Name"
            name="familyName"
            inputRef={register({ required: "Last name required" })}
            autoComplete="lname"
            defaultValue={userData.familyName}
          />
          {errors.familyName && (
            <p id="familyNameError" className={classes.signUpError}>
              {" "}
              {"* " + errors.familyName.message}{" "}
            </p>
          )}
        </Grid>
        <Grid item xs={6}>
          <FormControl
            variant="outlined"
            fullWidth
            margin="dense"
            className={classes.formControl}
          >
            <InputLabel htmlFor="outlined-country">Country</InputLabel>
            <Select
              required
              value={selectedCountry || extendedUserData.country}
              onChange={handleCountryChange}
              displayEmpty
              input={
                <OutlinedInput
                  labelWidth={60}
                  name="country"
                  id="outlined-country"
                />
              }
            >
              {countries.map(country => (
                <MenuItem key={country.code} value={country.code}>
                  {country.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl
            variant="outlined"
            fullWidth
            margin="dense"
            className={classes.formControl}
          >
            <InputLabel htmlFor="outlined-level">Level</InputLabel>
            <Select
              value={selectedLevel || extendedUserData.user_level}
              onChange={handleLevelChange}
              required
              input={
                <OutlinedInput
                  labelWidth={50}
                  name="level"
                  id="outlined-level"
                />
              }
            >
              <MenuItem value={"Beginner"}>Beginner</MenuItem>
              <MenuItem value={"Intermediate"}>Intermediate</MenuItem>
              <MenuItem value={"Advanced"}>Advanced</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            // style={{ height: "24px" }}
            control={
              <Checkbox
                defaultChecked={receiveMarketingEmail}
                defaultValue={receiveMarketingEmail}
                onClick={() => setReceiveMarketingEmail(!receiveMarketingEmail)}
                color="secondary"
                name="marketingEmail"
                id="marketingEmail"
              />
            }
            label="Send me Hatch Embroidery news, tips and special offers."
          />
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            type="submit"
            id="submit"
            fullWidth
            variant="contained"
            color="secondary"
            className={`${classes.submit} ${classes.profileSaveButton}`}
            buttonText="Save"
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            align="left"
            variant={"subtitle1"}
            className={classes.changePasswordHeading}
          >
            <b>Want to change your password?</b>
          </Typography>
          <Typography align="left" variant={"caption"}>
            We will sign you out and take you back to the sign in page.
            <br />
            On that page click the “Reset password?” button and follow the steps
            to change your password.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            fullWidth
            variant="outlined"
            onClick={handleChangePassword}
            color="secondary"
            className={classes.submit}
            buttonText="Change my password now"
            isLoading={isLoading}
          />
        </Grid>
        { !!hasUsedFlexpay && 
          <>
            <Grid item xs={12} style={{ paddingTop: 25 }}>
              <Typography
                align="left"
                variant={"subtitle1"}
              >
                <b>Want to update your FlexPay account details?</b>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="outlined"
                color="secondary"
                href={flexpayManagementUrl}
              >
                Manage FlexPay
              </Button>
            </Grid>          
          </>
        }
      </Grid>
    </form>
  );
  return profileEdit;
}
