import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

import useSignInStatus from "../hooks/useSignInStatus";
import wilcomStyles from "../theme/WilcomStyles";
const useStyles = makeStyles(wilcomStyles);

export default function IsAuthorized(args) {
const {authorizedComponent, placeholderComponent, props} = args
  const classes = useStyles();
  const { isLoaded, isSignedIn } = useSignInStatus();

  if (isLoaded) {
    if (isSignedIn) {
      return authorizedComponent;
    } else {
      console.log("Auth failed");
      props.history.push("/");
    }
  }
  return placeholderComponent || <CircularProgress className={classes.progress} />;
}
