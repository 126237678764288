import React, { Suspense, useEffect, useState } from "react";
import { ErrorProvider } from "./reducer/errorReducer";

import { MuiThemeProvider, makeStyles } from "@material-ui/core/styles";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

import GlobalSnackBar from "./components/GlobalSnackBar";
import WilcomIdFooter from "./components/WilcomIdFooter";
import useSignInStatus from "./hooks/useSignInStatus";
import { createWilcomIdMuiTheme } from "./theme/WilcomIdMuiTheme";
import { captureRedirect } from "./lib/redirectLib";
import { ROUTES } from "./lib/routesLib";

import Home from "./routes/Home";
import Profile from "./routes/Profile";
import Sessions from "./routes/Sessions";
import SignUp from "./routes/SignUp";
import SignIn from "./routes/SignIn";
import SignOut from "./routes/SignOut";
import ResetPassword from "./routes/ResetPassword";
import NewPassword from "./routes/NewPassword";
import ChangeEmail from "./routes/ChangeEmail";
import ManageFlexPay from "./routes/ManageFlexPay";
import MainLayout from "./layouts/MainLayout";

import wilcomStyles from "./theme/WilcomStyles";

captureRedirect();

const theme = createWilcomIdMuiTheme();

const useStyles = makeStyles(wilcomStyles);

function App() {
  const classes = useStyles();

  const [notification, showSnackNotification] = useState("");
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const { isSignedIn, userName } = useSignInStatus();
  const [error, setError] = useState(false);

  const clearNotification = () => {
    setSnackBarOpen(false);
    showSnackNotification("");
  };

  if (isSignedIn) {
    document.body.style.backgroundImage = "none";
  }

  useEffect(() => {
    function notify() {
      console.log({ notification });
      setSnackBarMessage(notification);
      setSnackBarOpen(true);
      setTimeout(() => clearNotification(), 20000);
    }
    if (notification && notification.length > 0) {
      notify();
    }
  }, [notification]);

  const errorMessage = <h3>An error occured: '{error}'</h3>;

  const router = (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        {isSignedIn ? (
          <Switch>
            <Route
              exact
              path={ROUTES.HOME}
              render={props => (
                <Home
                  showSnackNotification={showSnackNotification}
                  clearNotification={clearNotification}
                  forceSignIn={true}
                />
              )}
            />
            <Route
              path={ROUTES.RESET_PASSWORD}
              render={props => (
                <MainLayout
                  page={ROUTES.RESET_PASSWORD}
                  maxWidth={920}
                >
                  <ResetPassword
                    showSnackNotification={showSnackNotification}
                    clearNotification={clearNotification}
                  />
                </MainLayout>
              )}
            />
            <Route
              path={ROUTES.SIGN_OUT}
              render={props => (
                <SignOut
                  showSnackNotification={showSnackNotification}
                  clearNotification={clearNotification}
                />
              )}
            />
            <Route path={ROUTES.PROFILE} render={props => <Profile />} />
            <Route path={ROUTES.SESSIONS} render={props => <Sessions />} />
            <Route path={ROUTES.SIGN_UP} render={props => (<Redirect to={ROUTES.PROFILE} />)} />
            <Route path={ROUTES.CHANGE_EMAIL} render={props => <ChangeEmail />} />
            <Route path={ROUTES.MANAGE_FLEXPAY} render={props => <ManageFlexPay />} />
            <Route path={ROUTES.NEW_PASSWORD} render={props => (<Redirect to="/profile" />)} />
            <Route path={`${ROUTES.NEW_PASSWORD}:email?`} render={props => (<Redirect to="/profile" />)} />
          </Switch>
        ) : (
          <Switch>
            {/* <Route exact path="/" component={SignIn} /> */}
            <Route
              exact
              path={ROUTES.HOME}
              render={props => (
                <MainLayout
                  page={ROUTES.SIGN_IN}
                  maxWidth={954}
                >
                  <SignIn
                    showSnackNotification={showSnackNotification}
                    clearNotification={clearNotification}
                  />
                </MainLayout>
              )}
            />
            <Route
              path={ROUTES.SIGN_UP}
              render={props => (
                <MainLayout
                  page={ROUTES.SIGN_UP}
                  maxWidth={954}
                  buttonClass={classes.signUpHeaderButton}
                >
                  <SignUp />
                </MainLayout>
              )}
            />
            <Route
              path={ROUTES.RESET_PASSWORD}
              render={props => (
                <MainLayout
                  page={ROUTES.RESET_PASSWORD}
                  maxWidth={920}
                >
                  <ResetPassword
                    showSnackNotification={showSnackNotification}
                    clearNotification={clearNotification}
                  />
                </MainLayout>
              )}
            />
            <Route
              path={`${ROUTES.NEW_PASSWORD}/:email?`}
              render={props => (
                <MainLayout
                  page={ROUTES.NEW_PASSWORD}
                  maxWidth={920}
                >
                  <NewPassword />
                </MainLayout>
              )}
            />
            <Route
              path={ROUTES.SIGN_IN}
              render={props => (
                <MainLayout
                  page={ROUTES.SIGN_IN}
                  maxWidth={954}
                >
                  <SignIn
                    showSnackNotification={showSnackNotification}
                    clearNotification={clearNotification}
                  />
                </MainLayout>
              )}
            />
            <Route
              path={ROUTES.SIGN_OUT}
              render={props => (
                <SignOut
                  showSnackNotification={showSnackNotification}
                  clearNotification={clearNotification}
                />
              )}
            />
            <Route
              render={props => (
                <MainLayout
                  page={ROUTES.SIGN_IN}
                  maxWidth={920}
                >
                  <SignIn
                    showSnackNotification={showSnackNotification}
                    clearNotification={clearNotification}
                  />
                </MainLayout>
              )}
            />
          </Switch>
        )}
      </Suspense>
    </Router>
  );

  try {
    return (
      <MuiThemeProvider theme={theme}>
        <ErrorProvider>
          {router}
          <GlobalSnackBar
            snackBarOpen={snackBarOpen}
            snackBarMessage={snackBarMessage}
          />
          <WilcomIdFooter isSignedIn={isSignedIn} userName={userName} />
        </ErrorProvider>
      </MuiThemeProvider>
    );
  } catch (applicationError) {
    console.log({ applicationError });
    setError(applicationError);
    return <errorMessage />;
  }
}

export default App;
