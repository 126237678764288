import React from "react";
import Snackbar from "@material-ui/core/Snackbar";

export default function GlobalSnackBar(props) {
  const { snackBarOpen, snackBarMessage } = props;
  console.log({ snackBarOpen, snackBarMessage });

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
      open={snackBarOpen}
      message={snackBarMessage}
      // id="notificationMessage"
    />
  );
}
