import React, { useContext, useEffect, useState } from "react";
import { ErrorContext } from "../reducer/errorReducer";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";

import HatchHeaderLogo from "../components/HatchHeaderLogo";
import isAuthorized from "../components/IsAuthorized";
import WilcomIdAppBar from "../components/WilcomIdAppBar";
import useSignInStatus from "../hooks/useSignInStatus";
import wilcomStyles from "../theme/WilcomStyles";
import { Grid, Tooltip, withStyles } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import UpdatePaymentMethod from "../components/FlexpayUpdatePaymentMethod";
import { landingPage } from "../config/sso-config";
import {
  formatCost,
  getContractSummary,
  getSoftwareLevelName,
} from "../lib/flexpayLib";
import { getFlexpayDetailsAsync } from "../lib/flexpayApiLib";
const useStyles = makeStyles(wilcomStyles);

const bigCommerceUrl = landingPage.store;
const FlexpayStatuses = {
  ACTIVE: "Active",
  CANCELLED: "Cancelled",
  REFUNDED: "Refunded",
  COMPLETE: "Completed",
  SUSPENDED: "Suspended",
};
const FlexpayStatusColor = {
  SUSPENDED: "#D80E0E",
  CANCELLED: "#D80E0E",
  OTHER: "#000000",
};

// const ManageFlexpayView = {
//   DISPLAY: "display_details",
//   UPDATE: "update_card",
// };

const ADD_FLEXPAY_QTY = 1;
const numToMonthMapping = {
  "01": "Jan",
  "02": "Feb",
  "03": "Mar",
  "04": "Apr",
  "05": "May",
  "06": "Jun",
  "07": "Jul",
  "08": "Aug",
  "09": "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec",
};

function localeStyles() {
  return {
    sectionContainer: {
      border: "1px solid #DDDDDD",
      borderRadius: 5,
      padding: 10,
    },
    flexpayTitleArea: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      float: "left",
    },
    nextChargeRow: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    editCardHeading: {
      display: "flex",
      marginTop: 5,
      marginBottom: 15,
      alignSelf: "start",
    },
    updateDetailsContainer: {
      border: "1px solid #DDDDDD",
      borderRadius: 5,
      padding: 20,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    flexpayStatusContainer: {
      display: "flex",
      justifyContent: "center",
      padding: 5,
      paddingLeft: 30,
      paddingRight: 30,
      border: "solid 1px #E8E8E8",
      borderRadius: 3,
    },
    payRemainingContainer: {
      backgroundColor: "#15B96B",
      borderRadius: 5,
      color: "white",
      padding: 7,
      cursor: "pointer",
      marginLeft: 30,
      display: "flex",
    },
    reactivateFlexpayContainer: {
      backgroundColor: "white",
      border: "1px solid #15B96B",
      borderRadius: 5,
      color: "black",
      padding: 7,
      cursor: "pointer",
      display: "flex",
      width: 180,
    },
    suspendedTextContainer: {
      backgroundColor: FlexpayStatusColor.SUSPENDED,
      display: "inline-flex",
      borderRadius: 5,
      color: "white",
      padding: 12,
      marginBottom: 20,
      width: "100%",
      alignItems: "center",
    },
  };
}

export default function ManageFlexPay(props) {
  const classes = useStyles();
  const localClasses = makeStyles(localeStyles)();

  const CustomizedTooltip = withStyles({
    tooltip: {
      color: "#000000",
      backgroundColor: "#FFFFFF",
      fontSize: 14,
      boxShadow: "#00000029 0px 3px 6px",
    },
  })(Tooltip);
  const { isSignedIn, userName } = useSignInStatus();
  const { dispatch } = useContext(ErrorContext);

  const [status, setStatus] = useState("");
  const [softwareLevelName, setSoftwareLevelName] = useState("");
  const [contractSku, setContractSku] = useState("");
  const [contractDescription, setContractDescription] = useState("");
  const [monthsLeft, setMonthsLeft] = useState("");
  const [nextChargeDate, setNextChargeDate] = useState("");
  const [remainingBalance, setRemainingBalance] = useState("");
  const [contractEndDate, setContractEndDate] = useState("");
  const [cardCompany, setCardCompany] = useState("");
  const [cardEnding, setCardEnding] = useState("");
  const [cardExpiry, setCardExpiry] = useState("");
  const [billingAddress, setBillingAddress] = useState({});
  const [view, setView] = useState(true);
  const [suspendedLicenceEndDateText, setSuspendedLicenceEndDateText] =
    useState("");
  const [isSuspendLicenceDateInFuture, setIsSuspendLicenceDateInFuture] =
    useState(true);

  const addFlexpayToCart = (quantity = monthsLeft) => {
    window.location = `${bigCommerceUrl}/cart.php?action=add&sku=${contractSku}&qty=${quantity}`;
  };

  const toggleView = () => {
    setView(!view);
  };

  useEffect(() => {
    getFlexpayDetailsAsync().then((getFlexpayDetailsResponse) => {
      console.log({ getFlexpayDetailsResponse });
      if (!getFlexpayDetailsResponse) return;
      const {
        // flexpay_order_id,
        subscription_id,
        status,
        product_sku,
        currency,
        product_price,
        total_price,
        finished_count,
        installment_count,
        next_payment_date_value,
        end_payment_date_value,
        credit_card_ending,
        credit_card_expiry,
        credit_card_provider,
        billing_address,
        licence_end_date,
      } = getFlexpayDetailsResponse; 

      setIsSuspendLicenceDateInFuture(new Date() <= new Date(licence_end_date));
      setSuspendedLicenceEndDateText(new Date(licence_end_date).toDateString());
      setStatus(status);
      setContractSku(product_sku);
      setSoftwareLevelName(getSoftwareLevelName(product_sku));
      setContractDescription(
        getContractSummary(currency, product_price, installment_count)
      );

      setNextChargeDate(
        !next_payment_date_value || status === FlexpayStatuses.CANCELLED
          ? "-"
          : new Date(next_payment_date_value).toDateString()
      );
      setContractEndDate(
        !end_payment_date_value || status === FlexpayStatuses.CANCELLED
          ? "-"
          : new Date(end_payment_date_value).toDateString()
      );
      const calculatedMonthsLeft = installment_count - finished_count;
      const remainingBalanceWithoutTax = calculatedMonthsLeft * product_price;
      const totalRemainingBalance = calculatedMonthsLeft * total_price;
      setMonthsLeft(calculatedMonthsLeft);
      if (remainingBalanceWithoutTax > 0) {
        const taxAmount = parseFloat(
          (totalRemainingBalance - remainingBalanceWithoutTax).toFixed(2)
        );
        const taxAmountText = formatCost(currency, taxAmount, false);
        const withoutTaxText = formatCost(
          currency,
          remainingBalanceWithoutTax,
          false
        );
        const totalText = formatCost(currency, totalRemainingBalance);
        const finalText =
          taxAmount > 0
            ? `${totalText} (${withoutTaxText} + ${taxAmountText} tax)`
            : `${totalText}`;
        setRemainingBalance(finalText);
      }

      if(!subscription_id) {
        return;
      }
      setCardCompany(credit_card_provider);
      setCardEnding(credit_card_ending);
      const [year, monthNum] = credit_card_expiry?.split("-");
      setCardExpiry(`${numToMonthMapping[monthNum]} ${year}`);
      setBillingAddress(billing_address);
    });

    return () => {
      if (window.timer) clearTimeout(window.timer);
      dispatch({ type: "hide" });
    };
  }, [dispatch]);

  const ReactivateFlexpayContainer = (
    <div
      onClick={() => addFlexpayToCart(ADD_FLEXPAY_QTY)}
      className={localClasses.reactivateFlexpayContainer}
      style={{
        minWidth: 150,
        justifyContent: "center",
      }}
    >
      Re-activate FlexPay
    </div>
  );

  const PayRemainingFlexpayContainer = (
    <div
      onClick={() => addFlexpayToCart()}
      className={localClasses.payRemainingContainer}
    >
      Pay out remaining balance & complete FlexPay
    </div>
  );

  const CreditCardExpiryWarningContainer = (
    <div className={localClasses.suspendedTextContainer} style={{ backgroundColor: "orange" }}>
    <div>
      <InfoOutlinedIcon className={classes.infoIcon} />
    </div>
    <div>
        The card used when placing your FlexPay order was expiring soon. We will
        send you instructions on how to update your card details before your
        next FlexPay Installment is due.
      </div>
  </div>
  )

  const FlexpaySuspendedMessageContainer = (
    <div className={localClasses.suspendedTextContainer}>
      <div>
        <InfoOutlinedIcon className={classes.infoIcon} />
      </div>
      <div>
        Your FlexPay account is currently suspended and your Hatch licence{" "}
        {isSuspendLicenceDateInFuture && (
          <span>will expire on {suspendedLicenceEndDateText}</span>
        )}
        {!isSuspendLicenceDateInFuture && (
          <span>has expired from {suspendedLicenceEndDateText}</span>
        )}
        . <br />
        To continue using Hatch, please{" "}
        <span
          onClick={toggleView}
          style={{
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          update your payment details
        </span>{" "}
        by the next charge date. Once updated, your request will be processed
        the next day after 2AM Pacific Time.
      </div>
    </div>
  );

  const FlexpayCancelledMessageContainer = (
    <div className={localClasses.suspendedTextContainer}>
      <div>
        <InfoOutlinedIcon className={classes.infoIcon} />
      </div>
      <div>
        You Hatch FlexPay Account was cancelled. Your Hatch
        license has expired from {suspendedLicenceEndDateText}
        . <br />
        To continue using Hatch, please pay out your remaining balance or
        reactivate your FlexPay.
      </div>
    </div>
  );

  const FlexpayDetailsContainer = (
    <Grid container spacing={1} className={localClasses.sectionContainer}>
      <Grid
        item
        xs={12}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <b>{softwareLevelName}</b>
        <div
          className={localClasses.flexpayStatusContainer}
          style={{
            color:
              status === FlexpayStatuses.SUSPENDED
                ? FlexpayStatusColor.SUSPENDED
                : status === FlexpayStatuses.CANCELLED
                ? FlexpayStatusColor.CANCELLED
                : FlexpayStatusColor.OTHER,
          }}
        >
          {status}
        </div>
      </Grid>
      <Grid item xs={8}>
        <b>{contractDescription}</b>
      </Grid>
      <Grid item xs={8}>
        FlexPay months left: <b>{monthsLeft}</b>
      </Grid>
      <Grid item xs={12} className={localClasses.nextChargeRow}>
        <span>
          Next charge date: <b>{nextChargeDate}</b>
        </span>
      </Grid>
      {!!remainingBalance && (
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <span>
            {/* DISPLAY BALANCE WITH TAX */}
            Remaining balance: <b>{remainingBalance}</b>
          </span>
          {status !== FlexpayStatuses.COMPLETE &&
            status !== FlexpayStatuses.REFUNDED &&
            PayRemainingFlexpayContainer}
        </Grid>
      )}
      <Grid item xs={8}>
        Flexpay end date: <b>{contractEndDate}</b>
      </Grid>
      <Grid item xs={12}>
        {status === FlexpayStatuses.CANCELLED && ReactivateFlexpayContainer}
      </Grid>
    </Grid>
  );

  const ManageCreditCardContainer = (
    <Grid container spacing={1} className={localClasses.sectionContainer}>
      <Grid item xs={8}>
        <b>Manage Payment Options</b>
      </Grid>
      <Grid item xs={12}>
        <hr />
      </Grid>
      <Grid
        item
        xs={12}
        style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
      >
        <span>
          {cardCompany} (ending in {cardEnding})
        </span>
        <span>
          Expires in <b>{cardExpiry}</b>
        </span>
        <div
          onClick={toggleView}
          style={{
            backgroundColor: "#256b95",
            color: "white",
            borderRadius: 5,
            cursor: "pointer",
            padding: "8px 15px",
          }}
        >
          Update payment details
        </div>
      </Grid>
    </Grid>
  );

  const getFlexpayEndedNotification = () => {
    if (view) {
      switch (status) {
        case FlexpayStatuses.SUSPENDED:
          return FlexpaySuspendedMessageContainer;
        case FlexpayStatuses.CANCELLED:
          return FlexpayCancelledMessageContainer;
        case FlexpayStatuses.ACTIVE:
          if(!cardExpiry) {
            return CreditCardExpiryWarningContainer;
          }
          break;
        default:
          return null;
      }
    }
  };

  const authorizedComponent = (
    <div>
      <WilcomIdAppBar isSignedIn={isSignedIn} userName={userName} />
      <Container
        component="main"
        className={classes.mainContainer}
        maxWidth="md"
      >
        <CssBaseline />
        <div className={classes.paper}>
          <HatchHeaderLogo size="sm" />
          <br />
          {!view && (
            <Grid container fullWidth style={{ flexDirection: "column" }}>
              <br />
              <Grid
                container
                spacing={1}
                className={localClasses.updateDetailsContainer}
                fullWidth
              >
                <Grid item md={12} className={localClasses.editCardHeading}>
                  <ArrowBackIcon
                    onClick={toggleView}
                    style={{
                      cursor: "pointer",
                    }}
                  ></ArrowBackIcon>
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  <b>Edit Credit/Debit Card</b>
                </Grid>
                <UpdatePaymentMethod
                  currentBillingInfo={billingAddress}
                ></UpdatePaymentMethod>
              </Grid>
            </Grid>
          )}
          {!!view && (
            <div className={localClasses.flexpayTitleArea}>
              <h2>FlexPay </h2>
              <CustomizedTooltip
                title="Manage your Hatch instalments on this page"
                placement="right-end"
              >
                <InfoOutlinedIcon style={{ color: "#8D8D8D" }} />
              </CustomizedTooltip>
            </div>
          )}
          {getFlexpayEndedNotification()}
          {!!view && (
            <div>
              {/* Contract details */}
              {FlexpayDetailsContainer}
              {/* Manage credit card */}
              <br />
              <br />
              {status !== FlexpayStatuses.CANCELLED && !!cardExpiry && ManageCreditCardContainer}
            </div>
          )}
        </div>
      </Container>
    </div>
  );

  return isAuthorized({ authorizedComponent, props });
}
