import { Auth } from "aws-amplify";
import Cookies from "js-cookie";

import { redirectSignIn, secretServiceLogin } from "./serviceSignIn";
import { landingPage } from "../config/sso-config";

const SSO_REDIRECT = "SSO_REDIRECT";
const DOMAIN_WHITELIST =
  process.env.REACT_APP_DOMAIN_WHITE_LIST ||
  "wilcom-dev.mybigcommerce.com, wilcom-test.mybigcommerce.com, hatch-s-school.thinkific.com, hatchembroidery.com, *.hatchembroidery.com, *.wilcom.com, wilcom.com, *.ci.mywilcom.link, *.ci.embroideryworkspace.link";
const STORE_RETURN_URL = `https://${
  process.env.REACT_APP_STORE_DOMAIN || "hatchembroidery.com"
}`;

function isValidRedirectUrl(url) {
  function isValidHttpUrl() {
    let urlObj;
    try {
      urlObj = new URL(url);
    } catch (_) {
      console.error("invalid redirect url");
      return false;
    }
    const urlProtocol = urlObj.protocol;
    console.log("url protocol: ", urlProtocol);
    if (urlProtocol !== "http:" && urlProtocol !== "https:") {
      console.log("invalid redirect url protocol");
      return false;
    }
    return true;
  }

  function generateRegexForDomainWildcard(domain) {
    return new RegExp(
      `^${domain.replaceAll(".", "\\.").replaceAll("*", ".*")}$`
    );
  }

  function getAllTrustedDomains() {
    return DOMAIN_WHITELIST.replaceAll(" ", "").split(",");
  }

  function isValidDomain() {
    let urlObj;
    try {
      urlObj = new URL(url);
    } catch (_) {
      console.error("invalid redirect url");
      return false;
    }
    const urlDomain = urlObj.hostname;
    console.log("url domain: ", urlDomain);
    if (!urlDomain) {
      console.log("no redirect url domain specified");
      return false;
    }
    const trustedDomains = getAllTrustedDomains();
    for (const trustedDomain of trustedDomains) {
      const domainWildcardRegex = generateRegexForDomainWildcard(trustedDomain);
      if (domainWildcardRegex.test(urlDomain)) {
        return true;
      }
    }
    console.log("invalid redirect url domain");
    return false;
  }

  console.log(`validating redirect url: ${url}`);
  return isValidHttpUrl() && isValidDomain();
}

function getReturnUrlParam() {
  const urlParams = new URLSearchParams(window.location.search);
  let returnUrl = urlParams.get("return_url");
  console.log({ returnUrl });

  if (!returnUrl) {
    console.log("no return_url specified");
    return null;
  }

  if (!isValidRedirectUrl(returnUrl)) {
    returnUrl = STORE_RETURN_URL;
  }

  console.log({ returnUrl });
  return returnUrl;
}

export async function autoRedirect() {
  const returnUrl = getReturnUrlParam();

  if (!returnUrl) {
    return;
  }

  Auth.currentAuthenticatedUser()
    .then(() => {
      const referrerHost = new URL(returnUrl).host;
      const ssoRedirect = {
        referrerHost,
        returnUrl,
      };
      console.log({ ssoRedirect });
      redirectSignIn(ssoRedirect);
    })
    .catch(() => {
      console.log("no redirect, user not signed in");
    });
}

export async function captureRedirect() {
  const returnUrl = getReturnUrlParam();

  if (!returnUrl) {
    return;
  }

  const referrerHost = new URL(returnUrl).host;
  const cookie = {
    referrerHost,
    returnUrl,
  };
  Cookies.set(SSO_REDIRECT, cookie);
  console.log({ cookie });
}

export async function processRedirect(forceReturn = false) {
  const waitingCookie = Cookies.get(SSO_REDIRECT);
  console.log({ waitingCookie });

  if (waitingCookie) {
    const ssoRedirect = JSON.parse(waitingCookie);
    Cookies.remove(SSO_REDIRECT);
    if (forceReturn || (!!ssoRedirect && window.location.pathname === "/")) {
      redirectSignIn(ssoRedirect);
    }
  } else {
    if (forceReturn) {
      await secretServiceLogin();
      window.location = landingPage.store;
    }
  }
}
