import { Auth } from "aws-amplify";
import Cookies from "js-cookie";

import { updateUserReferrals } from "../lib/userLib";

export const REFERRER_CODE = "REFERRER_CODE";
export const REFERRER_CODE_EXPIRES_DAYS =  process.env.REFERRER_CODE_EXPIRES_DAYS || 60;

export async function captureReferral() {
  const referralCookie = Cookies.get(REFERRER_CODE);
  const urlParams = new URLSearchParams(window.location.search);
  const referrerCode = urlParams.get("referrer") || referralCookie;

  console.log({ referrerCode });
  if (!referrerCode) {
    console.log("no referrer");
    return;
  }

  try {
    console.log({ referrerCode });
    await Auth.currentAuthenticatedUser();
    await updateUserReferrals(referrerCode);
    Cookies.remove(REFERRER_CODE);
  } catch (error) {
    console.log("Not logged in. Store code for later.");
    Cookies.set(REFERRER_CODE, referrerCode, { expires: REFERRER_CODE_EXPIRES_DAYS });
  }
}
