import React from "react";

import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import hatchHeaderLogo from "../media/hatchWilcomLogoBlack.png";
import HeaderLogoSnackBar from './HeaderLogoSnackBar'
import wilcomStyles from "../theme/WilcomStyles";
const useStyles = makeStyles(wilcomStyles);

export default function HatchHeaderLogo(props) {
  const classes = useStyles();
  
  
  return (
    <Grid container direction='column' alignItems="center">
      <CardMedia
      className={props.size==="sm" ?classes.wilcomLogoSm: classes.wilcomLogo}
      image={hatchHeaderLogo}
      title="Hatch Embroidery, by Wilcom International"
    />
      <HeaderLogoSnackBar 
      className={classes.margin}
      // onClose={onClose}
    />
    </Grid>
    
  );
}
