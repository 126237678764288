import React, { useState, useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { ErrorContext } from "../reducer/errorReducer";

import { Auth } from "aws-amplify";

import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";

import HeaderLogoSnackBar from '../components/HeaderLogoSnackBar';

import { NavLink } from "react-router-dom";

import ShowPasswordAdornment from "../components/ShowPasswordAdornment";
import LoadingButton from "../components/LoadingButton";
import wilcomStyles from "../theme/WilcomStyles";

const useStyles = makeStyles(wilcomStyles);

export default function NewPassword(props) {
  const classes = useStyles();
  const { email } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { errors, register, handleSubmit } = useForm();
  const { dispatch, showMessage, state } = useContext(ErrorContext);
  const { show } = state;

  useEffect(() => {
    if (!!email) {
      showMessage(`Password reset code sent to ${email}.\nPlease check your inbox and enter code below.`, "success");
    }
  }, [email, showMessage]);

  useEffect(() => {
    return () => {
      if (window.timer) clearTimeout(window.timer);
      dispatch({ type: "hide" });
    };
  }, [dispatch]);

  async function onSubmit(data, event) {
    event.preventDefault();

    const formData = new FormData(event.target);
    const email = formData.get("email").trim();
    const code = formData.get("resetcode").trim();
    const newPassword = formData.get("newpassword").trim();

    setIsLoading(true);

    // Collect confirmation code and new password, then
    try {
      const forgotPasswordResponse = await Auth.forgotPasswordSubmit(email, code, newPassword)
      console.log({ forgotPasswordResponse });

      setShowForm(false);
      showMessage(
        `Your password has been changed successfully\nPlease continue to sign in with your new password.`,
        "success"
      );

      setTimeout(() => (window.location = "/"), 4000);
    } catch (forgotPasswordSubmitError) {
      console.error({ forgotPasswordSubmitError });

      const { code } = forgotPasswordSubmitError;
      let { message } = forgotPasswordSubmitError;

      if (code === "ExpiredCodeException") {
        message = "Confirmation code incorrect, Check your email inbox or go back to request a new code.";
      }

      if (code === "UserNotFoundException") {
        message = "The email does not match with the confirmation code";
      }

      showMessage(message, "error");
      setIsLoading(false);
    }
  }

  const snackbarWrapperStyle = {
    paddingBottom: show ? 44 : 0,
  };

  const resetPasswordHeading = (
    <Typography
      color="primary"
      component="h1"
      className={`${classes.formHeading}`}
      style={{ fontSize: "24px" }}
    >
      Reset your password
    </Typography>
  );

  return (
    <Container
      component="main"
      className={`${classes.mainContainer} ${classes.newPasswordContainer}`}
      maxWidth="xs"
    >
      <CssBaseline />
      <div
        className={classes.paper}
        style={{ padding: "44px" }}
      >
        <div
          style={snackbarWrapperStyle}
          className={classes.snackbarWrapper}
        >
          {!showForm &&
            <div style={{ marginBottom: "50px" }}>
              {resetPasswordHeading}
            </div>
          }
          <HeaderLogoSnackBar
            customClass={classes.snackbarCustomClass}
            showCloseButton={false}
          />
        </div>

        {showForm &&
          <>
            {resetPasswordHeading}
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                error={errors.hasOwnProperty("email")}
                id="email"
                label="Email"
                name="email"
                defaultValue={email || ""}
                inputRef={register({
                  required: "Email address required",
                  validate: value => !/^ +$/.test(value) || "Email address required"
                })}
                autoComplete="email"
                autoFocus
              />
              {errors.email && (
                <p id="emailError" className={classes.signUpError}>
                  {" "}
                  {"* " + errors.email.message}{" "}
                </p>
              )}
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="resetcode"
                error={errors.hasOwnProperty("resetcode")}
                inputRef={register({
                  required: "Confirmation code required (Check your email inbox)"
                })}
                label="Reset code"
                id="resetcode"
              />
              {errors.resetcode && (
                <p id="resetcodeError" className={classes.signUpError}>
                  {" "}
                  {"* " + errors.resetcode.message}{" "}
                </p>
              )}
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                type={!showPassword ? "password" : "text"}
                error={errors.hasOwnProperty("newpassword")}
                inputRef={register({
                  required: "New Password required",
                  validate: value =>
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/.test(value) ||
                    "Minimum requirements: 8 characters with both upper and lowercase letters and a number"
                })}
                InputProps={{
                  endAdornment:
                    <ShowPasswordAdornment
                      showPassword={showPassword}
                      setShowPassword={setShowPassword}
                    />
                }}
                id="newpassword"
                label="New Password"
                name="newpassword"
                onChange={(e) => setPassword(e.target.value)}
                autoFocus
              />
              {errors.newpassword && (
                <p id="newpasswordError" className={classes.signUpError}>
                  {" "}
                  {"* " + errors.newpassword.message}{" "}
                </p>
              )}
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                type={!showConfirmPassword ? "password" : "text"}
                error={errors.hasOwnProperty("confirmpassword")}
                inputRef={register({
                  required: "Confirm Password required",
                  validate: value => value === password ||
                    "Passwords do not match"
                })}
                InputProps={{
                  endAdornment:
                    <ShowPasswordAdornment
                      showPassword={showConfirmPassword}
                      setShowPassword={setShowConfirmPassword}
                    />
                }}
                id="confirmpassword"
                label="Confirm Password"
                name="confirmpassword"
                autoFocus
              />
              {errors.confirmpassword && (
                <p id="confirmpasswordError" className={classes.signUpError}>
                  {" "}
                  {"* " + errors.confirmpassword.message}{" "}
                </p>
              )}
              <LoadingButton
                type="submit"
                id="submit"
                fullWidth
                variant="contained"
                color="primary"
                style={{ height: "50px", marginTop: "52px" }}
                className={classes.submit}
                buttonText="Reset Password"
                isLoading={isLoading}
              />
            </form>
          </>
        }
        <Grid container>
          <Grid item xs>
            <Button
              size="small"
              color="primary"
              component={NavLink}
              to="/signin"
              className={classes.underlineButton}
            >
              {"Back to sign In"}
            </Button>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
