import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { ErrorContext } from "../reducer/errorReducer";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import wilcomStyles from "../theme/WilcomStyles";
import { makeStyles } from "@material-ui/core/styles";
import Stack from "@mui/material/Stack";
import { useState, useEffect, Fragment, useContext } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import {
  clearUserSession,
  getUserSessions,
} from "../lib/sessionLib";
const useStyles = makeStyles(wilcomStyles);

function createData(
  computerName,
  startTime,
  softwareVersion,
  status,
  sessionId
) {
  return { computerName, startTime, softwareVersion, status, sessionId };
}

export default function BasicTable(props) {
  const { onNotify } = props;
  const { dispatch } = useContext(ErrorContext);
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    return () => {
      if (window.timer) clearTimeout(window.timer);
      dispatch({ type: "hide" });
    };
  }, [dispatch]);

  function mapUserSessions(sessions) {
    const sessionResults = sessions.map((item) =>
      createData(
        item.ComputerName || "",
        moment(item.StartTime).format("D MMM YYYY"),
        item.VersionDescription,
        item.StatusName,
        item.SessionId
      )
    );
    return sessionResults;
  }

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const sessionList = await getUserSessions();
        console.log({ sessionList });
        setRows(mapUserSessions(sessionList.sessions));
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    }

    fetchData();
  }, []);

  function endSession(sessionId, computerName) {
    setIsLoading(true);
    clearUserSession(sessionId)
      .then((response) => {
        console.log(response);
        const msg =
          "Your Hatch session on " +
          computerName +
          " has been successfully ended.";
        onNotify(msg, "success");
        getUserSessions()
          .then((response) => {
            console.log({ response });
            setRows(mapUserSessions(response.sessions));
            setIsLoading(false);
          })
          .catch((getNewSessionsError) => {
            console.log({ getNewSessionsError });
            setIsLoading(false);
          });
      })
      .catch((endSessionErr) => {
        console.log({ endSessionErr });
        if (
          endSessionErr.response &&
          endSessionErr.response.data.code === "SESSION_LIMIT_EXCEEDED"
        ) {
          onNotify(endSessionErr.response.data.message, "error");
        } else {
          onNotify(endSessionErr.message, "error");
        }
        setIsLoading(false);
      });
  }
  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Computer Name</TableCell>
            <TableCell>Start Time</TableCell>
            <TableCell>Software Version</TableCell>
            <TableCell>Status</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading
            ? null
            : rows.map((row) => (
                <TableRow
                  key={row.computerName}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  style={{ height: "100px" }}
                >
                  <TableCell component="th" scope="row">
                    <b>{row.computerName}</b>
                  </TableCell>
                  <TableCell>{row.startTime}</TableCell>
                  <TableCell>{row.softwareVersion}</TableCell>
                  <TableCell
                    style={{
                      color: row.status === "Active" ? "#276A95" : "#E63838",
                    }}
                  >
                    {row.status}{" "}
                  </TableCell>
                  <TableCell align="center">
                    <button
                      className={classes.endSessionBtn}
                      onClick={() =>
                        endSession(row.sessionId, row.computerName)
                      }
                    >
                      End Session
                    </button>
                  </TableCell>
                </TableRow>
              ))}
        </TableBody>
      </Table>
      <Stack
        style={{ display: rows.length === 0 && !isLoading ? "flex" : "none" }}
        height="160px"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        You do not have any sessions. When you sign in to your Hatch software,
        the sessions details will be shown here.
      </Stack>
      <Stack
        style={{ display: isLoading ? "flex" : "none" }}
        height="160px"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Fragment>
          <br />
          <CircularProgress variant="indeterminate" size={36} thickness={4} />
        </Fragment>
      </Stack>
    </TableContainer>
  );
}
