const IFRAME_LOADING_TIMEOUT = Number(process.env.REACT_APP_IFRAME_LOADING_TIMEOUT) || 10000;

export default async function iframeLoadAsync(iframeTarget, location) {
  const target = typeof iframeTarget === "string" ? document.getElementById(iframeTarget) : iframeTarget;
  const promise = new Promise((resolve, reject) => {
    try {
      // Resolve promise if iframe successfully loaded
      target.addEventListener("load", function () {
        console.log(`iframe ${location} successfully loaded`);
        clearTimeout(timeoutId);
        setInterval(() => resolve(`iframe ${location} successfully loaded`), 2000);
      });

      const timeoutId = setTimeout(() => {
        console.log({ target3: target });
        reject("Timeout when loading iframe")
      }, IFRAME_LOADING_TIMEOUT);

      target.src = location;
    }
    catch (iframeLoadAsyncError) {
      console.error({ iframeLoadAsyncError });
      reject(iframeLoadAsyncError);
    }
  });
  return promise;
}

export async function secretIframeAsync(frameSrcUrls, hostControl) {
  try {
    const parentControl = hostControl || document.body;
    const loadingFrames = [];

    frameSrcUrls.forEach(url => {
      const ifrm = document.createElement("iframe");
      ifrm.style.width = "1x";
      ifrm.style.height = "1px";
      ifrm.style.display = "none";
      parentControl.appendChild(ifrm);
      loadingFrames.push(iframeLoadAsync(ifrm, url));
    });

    return await Promise.all(loadingFrames);
  } catch (secretIframeAsyncError) {
    console.warn({ secretIframeAsyncError });
  }
}
