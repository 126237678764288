import { API, Auth } from "aws-amplify";
import { cognitoApiDefautHeaders } from "./authLib";

const apiName = "wilcomIdApi";

export async function checkHasFlexpayAsync() {
  const path = "/flexpay/check";
  const myInit = {
    headers: cognitoApiDefautHeaders(await Auth.currentSession()),
  };
  console.log({ myInit });

  return await API.get(apiName, path, myInit);
}

export async function getFlexpayDetailsAsync() {
  const path = "/flexpay/details";
  const myInit = {
    headers: cognitoApiDefautHeaders(await Auth.currentSession()),
  };
  console.log({ myInit });

  return await API.get(apiName, path, myInit);
}

export async function updateFlexpayDetailsAsync(data) {
  const path = "/flexpay/details";
  const myInit = {
    headers: cognitoApiDefautHeaders(await Auth.currentSession()),
    body: data,
  };
  console.log({ myInit });

  return await API.put(apiName, path, myInit);
}
