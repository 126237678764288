import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, GridList, GridListTile, Link, CardMedia } from "@material-ui/core";

import { landingPage } from "../config/sso-config";
import wilcomStyles from "../theme/WilcomStyles";

const useStyles = makeStyles(wilcomStyles);

export default function LogoFooter(props) {
  const classes = useStyles();
  return (
    <Container className={classes.logoContainer} maxWidth="sm">
      <GridList cellHeight={88} className={classes.logoGridList} cols={4}>
        <GridListTile key={"hatchWebsiteLogo"} cols={1}>          
          <Link href={landingPage.store}>
            <CardMedia
              image="/site-logos/hatch-website.png"
              title="Return to the Hatch Embroidery website"
              className={classes.footerLogoCard}
            />
          </Link>
        </GridListTile>
        <GridListTile key={"hatchAcademy3Logo"} cols={1}>          
          <Link href={landingPage.academy3}>
            <CardMedia
              image="/site-logos/hatch-academy.png"
              title="Hatch Academy"
              className={classes.footerLogoCard}
            />
          </Link>
        </GridListTile>
        <GridListTile key={"hatchCommunityLogo"} cols={1}>          
          <Link href={landingPage.community}>
            <CardMedia
              image="/site-logos/hatch-community.png"
              title="Hatch Community"
              className={classes.footerLogoCard}
            />
          </Link>
        </GridListTile>
        <GridListTile key={"hatchBlogLogo"} cols={1}>          
          <Link href={landingPage.blog}>
            <CardMedia
              image="/site-logos/hatch-blog.png"
              title="Hatch Blog"
              className={classes.footerLogoCard}
            />
          </Link>
        </GridListTile>
      </GridList>
    </Container>
  );
}
