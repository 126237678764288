import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Warning } from "@material-ui/icons";

import wilcomStyles from "../theme/WilcomStyles";
import { themeConfig } from "../config/theme-config";

const useStyles = makeStyles(wilcomStyles);

export default function WilcomIdFooter(props) {
  const classes = useStyles();
  const { IsNotProd, Environment } = themeConfig;

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      {!!IsNotProd &&
        (
          <BottomNavigation showLabels className={classes.footerAppBar}>
            <BottomNavigationAction
              label={Environment}
              icon={<Warning color="primary" href="/" />}
              className={classes.wilcomFooterIcon}
            />
          </BottomNavigation>
        )
      }
    </Container>
  );
}
