const ENV = "prod";
const NOT_PROD = ENV.toLowerCase() !== "prod";

export const themeConfig = {
    // prod: ""
    // ci: "#3BAD3D"
    // test: "#AF84D0"
  primaryColorOverride: NOT_PROD ? "" : null,
  Environment: ENV,
  IsNotProd: NOT_PROD 
}