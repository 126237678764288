import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";

import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

import wilcomStyles from "../theme/WilcomStyles";
import { ROUTES } from "../lib/routesLib";

const useStyles = makeStyles(wilcomStyles);

export default function MainLayout({ page, maxWidth, buttonClass, children }) {
  const classes = useStyles();
  console.log({ page, maxWidth });

  const [buttonUrl, setButtonUrl] = useState("");
  const [buttonText, setButtonText] = useState("");
  const [buttonWidth, setButtonWidth] = useState(220);

  useEffect(() => {
    const queryParams = document.location.search;

    switch (page) {
      case ROUTES.SIGN_UP:
        setButtonUrl(`${ROUTES.SIGN_IN}${queryParams}`);
        setButtonText("Existing Account? \nSign in");
        setButtonWidth(232)
        break;
      default:
        setButtonUrl(`${ROUTES.SIGN_UP}${queryParams}`);
        setButtonText("Create account");
        setButtonWidth(222);
        break;
    }
  }, [setButtonUrl, setButtonText, setButtonWidth, page]);

  return (
    <div style={{ minHeight: "100vh" }}>
      <header
        className={classes.headerWrapper}
        style={{ maxWidth }}
      >
        <div>
          <img
            src="/hatch-wilcom-white-logo.png"
            className={classes.mainLayoutHatchLogo}
            alt="Hatch by Wilcom logo"
            
          />
        </div>
        <div>
          <Button
            color="inherit"
            variant="outlined"
            style={{ width: buttonWidth }}
            className={`${classes.headerButton} ${buttonClass}`}
            component={NavLink}
            to={buttonUrl}
          >
            {buttonText}
          </Button>
        </div>
      </header>
      <>{children}</>
    </div>
  )
}