const serviceConfig = {
  aws_project_region: "ap-southeast-2",
  aws_cognito_region: "ap-southeast-2",
  aws_user_pools_id: "ap-southeast-2_TGo8iU7lY",
  aws_user_pools_web_client_id: "1u12momhp2ltg026fkr67dl2sv",
  API: {
    endpoints: [
      {
        name: "wilcomIdApi",
        endpoint: "https://prod.api.wilcom.live/prod"
      }
    ]
  }
};
export default serviceConfig;
