import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/styles";
import wilcomStyles from "../theme/WilcomStyles";

const useStyle = makeStyles(wilcomStyles);

export default function ExistingUserDialogue(props) {
  const { show, existingUserEmail, onResponse } = props;
  const onAgree = () => onResponse(true);
  const onDisgree = () => onResponse(false);
  const classes = useStyle();
  return (
    <React.Fragment>
      <Dialog
        open={show}
        onClose={onDisgree}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Ready to link your old Hatch Embroidery account?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            className={classes.existingUserDialog}
          >
            The email address{" "}
            <span>
              <i>{existingUserEmail}</i>
            </span>{" "}
            is already registered on the old Hatch website.
            <br />
            <br />
            <span>PLEASE NOTE</span> – after linking your accounts, the password
            selected today will be required when running your Hatch Embroidery
            software.
            <br />
            <br /> Do you wish to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onDisgree} color="primary">
            NO
          </Button>
          <Button
            onClick={onAgree}
            color="primary"
            variant="contained"
            autoFocus
          >
            YES
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
