export const hostRedirect = {
  "hatchembroidery.com": { isStore: true },
  "academy.hatchembroidery.com": { isAcademy3: true },
  "": { isBlog: true },
  "community.hatchembroidery.com": { isCommunity: true },
};

export const signOutUrl = {
  store: "https://hatchembroidery.com/login.php?action=logout",
  academy3: "https://academy.hatchembroidery.com/users/sign_out",
  dnn: "http://www.wilcom.com/Home/tabid/56/ctl/Logoff/language/en-US/Default.aspx",
};

export const landingPage = {
  store: "https://hatchembroidery.com",
  academy3: "https://academy.hatchembroidery.com",
  community: "https://community.hatchembroidery.com",
  blog: "https://",
};
