export const getSoftwareLevelName = (sku) => {
  if (sku === "WHSFP-03") {
    return "Hatch Embroidery Composer 3";
  }
  return "Hatch Embroidery Digitizer 3";
};

export const formatCost = (currency, value, displayCurrency = true) => {
  let currencySymbol = "";
  switch (currency) {
    case "USD":
    case "AUD":
    default:
      currencySymbol = "$";
  }
  return displayCurrency
    ? `${currency} ${currencySymbol}${value.toFixed(2)}`
    : `${currencySymbol}${value.toFixed(2)}`;
};

export const getContractSummary = (
  currency,
  installmentAmount,
  installmentCount,
  timePeriod = "month"
) => {
  return `${formatCost(
    currency,
    installmentAmount
  )} ( per ${timePeriod} for ${installmentCount} ${timePeriod}s )`;
};
