import React, { Fragment, useState, useContext, useEffect } from "react";
import { ErrorContext } from "../reducer/errorReducer";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import CircularProgress from "@material-ui/core/CircularProgress";
import CardMedia from "@material-ui/core/CardMedia";

import { processRedirect } from "../lib/redirectLib";
import { getExtendedUserDataAsync } from "../lib/userLib";
import HatchHeaderLogo from "../components/HatchHeaderLogo";
import isAuthorized from "../components/IsAuthorized";
import LogoFooter from "../components/LogoFooter";
import ProfileInfo from "../components/ProfileInfo";
import WilcomIdAppBar from "../components/WilcomIdAppBar";
import useSignInStatus from "../hooks/useSignInStatus";
import wilcomStyles from "../theme/WilcomStyles";
import { Button, Grid, TextField, Typography } from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import corelDrawLogo from "../media/icons/corel-draw-icon.png";
const useStyles = makeStyles(wilcomStyles);

export default function Home(props) {
  const classes = useStyles();
  const [extendedUserData, setExtendedUserData] = useState("");
  const [extendedUserDataIsLoading, setExtendedUserDataIsLoading] = useState(
    ""
  );
  const [showHatch3Download, setShowHatch3Download] = useState(false);
  const { isSignedIn, userName, userData } = useSignInStatus();
  const { dispatch, showMessage } = useContext(ErrorContext);
  useEffect(() => {
    return () => {
      if (window.timer) clearTimeout(window.timer);
      dispatch({ type: "hide" });
    };
  }, [dispatch]);
  //test: http://localhost:3000/?return_url=http://www.abc.net.au
  processRedirect();

  function updateUserData() {
    console.log(`Updating ${userName}`);
    setExtendedUserDataIsLoading(true);
    getExtendedUserDataAsync(userName)
      .then(extendedData => {
        console.log({ extendedData });
        if (!!extendedData && extendedData.country) {
          //user hasn't completed sign in
          setExtendedUserData(extendedData);
          // Determine whether user should see re-download Hatch 3 link
          const customerGroupParts = extendedData?.customer_group?.split("-");
          console.log({ customerGroupParts })
          if(customerGroupParts[1]?.match(/(WHO|WHD|WHP|WHS)03/) || customerGroupParts[0]?.match(/(TA|TX)/)) {
            setShowHatch3Download(true);
          }
        } else {
          showMessage("signing out...", "success");

          window.location = "/signout";
        }
      })
      .finally(data => setExtendedUserDataIsLoading(false));
  }
  function onProfileUpdateComplete() {
    showMessage(
      `Update successful! Select one of the boxes below to continue.`,
      "success"
    );
  }
  function onProfileUpdateNotify(notification) {
    showMessage(notification, "error");
  }

  function copyToClipboard(elementId) {
    var copyText = document.getElementById(elementId);
    navigator.clipboard.writeText(copyText.value);
  }

  if (!!userName && !extendedUserData && !extendedUserDataIsLoading) {
    updateUserData();
  }

  const authorizedComponent = (
    <div>
      <WilcomIdAppBar isSignedIn={isSignedIn} userName={userName} />
      <Container
        component="main"
        className={classes.mainContainer}
        maxWidth="sm"
      >
        <CssBaseline />
        <div className={classes.paper}>
          <HatchHeaderLogo />
          <br />
          {!extendedUserData ? (
            <Fragment>
              <br />
              <CircularProgress
                variant="indeterminate"
                size={36}
                thickness={4}
              />
            </Fragment>
          ) : (
            <ProfileInfo
              userName={userName}
              userData={userData}
              extendedUserData={extendedUserData}
              onComplete={onProfileUpdateComplete}
              onNotify={onProfileUpdateNotify}
              onFinally={updateUserData}
            />
          )}
        </div>
      </Container>

      {(!!extendedUserData.corelKey || extendedUserData.corelKey2024) && (
        <Container component="main" className={classes.mainContainer} maxWidth="sm" style={{ marginTop: "1rem" }}>
          {!!extendedUserData.corelKey2024 && (
            <div>
              <Grid>
                <div className={classes.corelDrawTitleWrapper}>
                  <CardMedia className={classes.corelDrawLogo} image={corelDrawLogo} title="CorelDRAW®" />
                  <Typography className={classes.corelDrawTitle}>Your CorelDRAW® Standard 2024 Product Key</Typography>
                </div>
              </Grid>
              <Grid item xs={12} style={{ display: "flex", gap: 10 }}>
                <TextField
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  id="corelDrawKey2024"
                  label="CorelDraw Product Key"
                  value={extendedUserData.corelKey2024}
                  InputProps={{
                    classes: {
                      input: classes.corelKeyInput,
                    },
                  }}
                  disabled
                />
                <Button onClick={() => copyToClipboard("corelDrawKey2024")}>
                  <FileCopyIcon />
                </Button>
              </Grid>
              <Grid>
                <Typography>
                  <a href={process.env.REACT_APP_COREL_DRAW_2024_DOWNLOAD_LINK} className={classes.downloadCorelDrawLink}>Download CorelDRAW® 2024</a>
                </Typography>
              </Grid>
            </div>
          )}

          {!!extendedUserData.corelKey && (
            <div>
              <Grid style={{ marginTop: "2rem" }}>
                <div className={classes.corelDrawTitleWrapper}>
                  <CardMedia className={classes.corelDrawLogo} image={corelDrawLogo} title="CorelDRAW®" />
                  <Typography className={classes.corelDrawTitle}>Your CorelDRAW® Standard 2021 Product Key</Typography>
                </div>
              </Grid>
              <Grid item xs={12} style={{ display: "flex", gap: 10 }}>
                <TextField
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  id="corelDrawKey"
                  label="CorelDraw Product Key"
                  value={extendedUserData.corelKey}
                  InputProps={{
                    classes: {
                      input: classes.corelKeyInput,
                    },
                  }}
                  disabled
                />
                <Button onClick={() => copyToClipboard("corelDrawKey")}>
                  <FileCopyIcon />
                </Button>
              </Grid>
              <Grid>
                <Typography>
                  <a href={process.env.REACT_APP_COREL_DRAW_DOWNLOAD_LINK} className={classes.downloadCorelDrawLink}>
                    Download CorelDRAW® 2021
                  </a>
                </Typography>
              </Grid>
            </div>
          )}
        </Container>
      )}
      {showHatch3Download && (
        <Container
          component="main"
          className={classes.mainContainer}
          maxWidth="sm"
          style={{ paddingTop: "1rem", marginTop: "0", backgroundColor: "unset", border: "none" }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography align="left" variant={"subtitle1"} className={classes.changePasswordHeading}>
                <b>Re-download Hatch 3</b>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button href={process.env.REACT_APP_HATCH3_DOWNLOAD_LINK} fullWidth variant="outlined" color="secondary">
                Download Hatch 3
              </Button>
            </Grid>
          </Grid>
        </Container>
      )}
      <LogoFooter />
    </div>
  );

  return isAuthorized({ authorizedComponent, props });
}
