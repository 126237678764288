import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import wilcomStyles from "../theme/WilcomStyles";

const useStyles = makeStyles(wilcomStyles);

export default function CustomerReview({ reviewContent, reviewerName, reviewerTitle, imageUrl, starsImageUrl }) {
  const classes = useStyles();

  return (
    <div className={classes.customerReviewWrapper}>
      <div className={classes.reviewContentWrapper}>
        {reviewContent}
      </div>
      <div style={{ marginTop: "34px" }}></div>
      <div className={classes.reviewerComponentWrapper}>
        <div
          style={{ marginRight: "16px" }}
        >
          <img
            src={imageUrl}
            width="55"
            alt="The reviewer"
          />
        </div>
        <div>
          <div className={classes.reviewerNameText}>
            {reviewerName}
          </div>
          <div>
            {reviewerTitle}
          </div>
          <div>
            <img src={starsImageUrl} alt="Review stars" />
          </div>
        </div>
      </div>
    </div>
  );
}

CustomerReview.defaultProps = {
  starsImageUrl: "/5-stars.svg",
};