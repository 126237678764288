import backgroundImage from "../media/bgHatchEmbroidery03.jpg";

export default function wilcomStyles(theme) {
  return {
    "@global": {
      body: {
        backgroundColor: theme.palette.common.lightGrey,
        backgroundImage: `url(/portal-background.png)`, // This will become the blue background
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat"
      }
    },
    tableContainer: {
      backgroundColor: "#F7F7F7 !important",
      height: "fit-content !important",
      boxShadow: "none !important",
      borderColor: "0 !important"
    },
    endSessionBtn: {
      width: "132px",
      height: "40px",
      border: "1px solid #D6D6D6",
      borderRadius: "3px",
      cursor: "pointer",

      "&:hover": {
        color: "#FFFFFF",
        backgroundColor: "#276A95",
      }
    },
    wilcomLogoSm: {
      width: "94px",
      height: "40px",
    },
    root: {
      flexGrow: 1
    },
    wilcomAppBar: {
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: "cover"
    },
    menuButton: {
      marginRight: theme.spacing(2)
    },
    signOutButton: {
      marginLeft: theme.spacing(2)
    },
    titleAnon: {
      left: 0,
      position: "absolute",
      textAlign: "center",
      width: "100%",
      minWidth: 200,
      flexGrow: 1,
      color: theme.palette.common.white
    },
    title: {
      marginLeft: theme.spacing(1),
      minWidth: 200,
      flexGrow: 1,
      color: theme.palette.common.white
    },
    menuLink: {
      textDecoration: "none",
      color: theme.palette.common.white
    },
    menuNavLink: {
      [theme.breakpoints.down("xs")]: {
        display: "none"
      },
      marginRight: theme.spacing(2),
      color: theme.palette.common.white,
      cursor: "pointer"
    },
    menuMiniLogo: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("xs")]: {
        display: "none"
      },
      [theme.breakpoints.down("xs")]: {
        display: "block",
        height: 40,
        width: 40
      }
    },
    menuLogo: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("xs")]: {
        display: "block",
        height: 40,
        width: 93
      },
      [theme.breakpoints.down("xs")]: {
        display: "none"
      }
    },
    menuIconLogo: {
      [theme.breakpoints.up("xs")]: {
        display: "block",
        height: 48,
        width: 48
      },
      [theme.breakpoints.down("xs")]: {
        display: "block",
        height: 36,
        width: 36
      }
    },
    secretSignInContainer: {
      display: "none"
    },
    mainContainer: {
      marginTop: "8%", //theme.spacing(8),
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: theme.palette.common.darkGrey,
      backgroundColor: theme.palette.common.white,
      paddingTop: 40,
      paddingRight: 60,
      paddingLeft: 60,
      paddingBottom: 40,
      "@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)": {
        marginTop: "8%", //theme.spacing(8),
        borderStyle: "none",
        backgroundColor: "transparent"
      }
    },
    logoContainer: {
      marginTop: "2%",
      padding: theme.spacing(1)
    },
    paper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    signUpForm: {
      margin: theme.spacing(4)
    },
    signUpButton: {
      height: "50px",
      margin: theme.spacing(0.5, 0, 0)
    },
    passwordHelperText: {
      color: '#59c6c8',
      textAlign: 'center',
      fontSize: '10px',
      fontWeight: '600',
      margin: '2px 0'
    },
    signUpError: {
      margin: 0,
      color: "rgba(255,0,0,.7)",
      fontWeight: 520,
      fontStyle: "italic"
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.common.darkGrey
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(0)
    },
    buttonSpinner: {
      color: theme.palette.common.white,
      float: "right"
    },
    wilcomLogo: {
      width: 168,
      height: 72,
      marginBottom: theme.spacing(1)
    },
    hatchLogo: {
      height: 69,
      width: 183
    },
    signUpHeader: {
      margin: theme.spacing(1)
    },
    signUpImage: {
      backgroundImage: "url(/hatch-banner.jpg)",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "top left"
    },
    whiteLogoImage: {
      backgroundImage: "url(/hatch-wilcom-white-logo.png)",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "top left"
    },
    signInButton: {
      margin: theme.spacing(2, 0, 0),
      padding: "13px",
    },
    signInNavLink: {
      margin: theme.spacing(2, 0, 2, 0)
    },
    formSpaced: {
      margin: theme.spacing(1)
    },
    navLink: {
      textDecoration: "none",
      color: theme.palette.primary.main
    },
    snackbar: {
      success: {
        backgroundColor: "#00AA00"
      },
      error: {
        backgroundColor: theme.palette.error.dark
      },
      info: {
        backgroundColor: theme.palette.primary.main
      },
      warning: {
        backgroundColor: "#bb4400"
      },
      icon: {
        fontSize: 28
      },
      iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1)
      },
      message: {
        display: "flex",
        alignItems: "center"
      }
    },
    localSnackbar: {
      margin: "auto",
      width: "100%"
    },
    progress: {
      margin: theme.spacing(2)
    },
    SignoutFrames: {
      display: "none"
    },
    footerAppBar: {
      bottom: 0,
      marginTop: theme.spacing(3),
      left: "50%",
      right: "50%",
      background: "none"
    },
    wilcomFooterIcon: {
      height: "24px",
      width: "100%"
    },
    footerLogoCard: {
      width: "100%",
      height: "100%"
    },
    profileInfoTextField: {
      fontSize: "smaller"
    },
    termsAndConditions: {
      fontSize: "79%",
      textAlign: "justify"
    },
    confirmCodeInput: {
      margin: theme.spacing(1, 0),
      marginBottom: "28px",
    },
    confirmCardText: {
      width: "100%",
      fontWeight: 400,
      fontSize: "14px",
      textAlign: "left",
      color: "black",
      marginTop: theme.spacing(1),
      marginBottom: "20px",
    },
    confirmationCodeLabel: {
      fontSize: "14px",
      fontWeight: "bold",
      color: "#1F2533",
    },
    codeInputText: {
      textAlign: "center",
      fontSize: "24px",
      fontWeight: "bold",
      letterSpacing: "1.2px"
    },
    resendCodeButton: {
      fontWeight: "bold",
      textAlign: "left",
      paddingLeft: 0,
      textDecoration: "underline",
      marginTop: "11px",
      color: "#256B95"
    },
    newPasswordContainer: {
      maxWidth: "538px",
      borderRadius: "15px",
      padding: "0",
      border: "none",
      marginTop: "0",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        maxWidth: "none",
        borderRadius: "0",
        height: "calc(100vh - 124px)"
      }
    },
    newPasswordButtonWrapper: {
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column-reverse",
      }
    },
    existingUserDialog: {
      textAlign: "justify",
      "& span": {
        color: "black",
        fontWeight: "bold",
        display: "inline"
      }
      // textJustify: "inter-word"
    },
    changePasswordHeading: {
      height: "42px",
      lineHeight: "42px"
    },
    corelKeyInput: {
      color: "#424242"
    },
    corelDrawTitleWrapper: {
      display: "flex",
      alignItems: "center",
      justidyContent: "center",
      marginBottom: "18px"
    },
    corelDrawTitle: {
      fontSize: "1rem"
    },
    corelDrawLogo: {
      width: "24px",
      height: "24px"
    },
    downloadCorelDrawLink: {
      color: "#256B95"
    },
    infoIcon: {
      color: 'white',
      marginRight: '8px'
    },
    signInContainer: {
      maxWidth: "960px",
      height: "758px",
      borderRadius: "15px",
      padding: "0",
      border: "none",
      marginTop: "0",
      [theme.breakpoints.down("sm")]: {
        borderRadius: "0"
      },
      [theme.breakpoints.down("xs")]: {
        maxHeight: "calc(100vh - 124px)"
      }
    },
    signInForm: {
      padding: "70px",
      boxSizing: "border-box",
      width: "66.5%",
      [theme.breakpoints.down("sm")]: {
        width: "100%"
      },
      [theme.breakpoints.down("xs")]: {
        padding: "70px 36px",
      }
    },
    signInMainHeading: {
      fontSize: "36px",
      marginBottom: "26px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "24px"
      }
    },
    signInSubHeading: {
      fontSize: "22px",
      marginBottom: "55px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "14px",
        marginBottom: "36px"
      }
    },
    signInList: {
      listStyleType: "none",
      "& li": {
        color: "#1F2533",
        fontSize: "16px",
        marginBottom: "14px",
        position: "relative",
        "&::before": {
          content: "''",
          position: "absolute",
          display: "list-item",
          top: "-2px",
          left: "-34px",
          width: "22px",
          height: "22px",
          backgroundImage: "url('/hatch-bullet.svg')",
          background: "no-repeat"
        }
      }
    },
    formHeading: {
      marginRight: "auto",
      marginTop: "0",
      fontWeight: "bold",
    },
    snackbarWrapper: {
      width: "100%"
    },
    snackbarCustomClass: {
      "& .MuiSnackbarContent-message": {
        width: "100%"
      }
    },
    // Discovery component classes
    signUpDiscoveryMenuAdornment: {
      height: "56px",
      borderLeft: "1px solid #CECECE",
      maxHeight: "none",
      paddingLeft: "12px",
      color: "black",
      cursor: "pointer"
    },
    signUpDiscoveryMenuPaper: {
      width: "298px",
      border: "2px solid #256B95",
      borderTop: "none",
      [theme.breakpoints.down("xs")]: {
        border: "2px solid #256B95",
      }
    },
    signUpDiscoveryMenuItem: {
      height: "36px !important",
      fontSize: "14px !important"
    },
    signUpDiscoveryMenuOtherForm: {
      display: "flex",
      flexDirection: "column",
      paddingRight: "20px",
      marginLeft: "58px"
    },
    signUpContainer: {
      maxWidth: "960px",
      borderRadius: "15px",
      padding: "0",
      border: "none",
      marginTop: "0",
      minHeight: "760px",
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        borderRadius: "0"
      }
    },
    signUpFormWrapper: {
      display: "flex",
      maxWidth: "100%"
    },
    mainSignUpForm: {
      padding: "52px 56px",
      width: "636px",
      [theme.breakpoints.down("sm")]: {
        padding: "52px 36px 126px",
        width: "100%"
      },
    },
    signUpMainHeading: {
      fontSize: "36px",
      marginBottom: "10px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "24px",
        marginBottom: "22px"
      }
    },
    signUpSubHeading: {
      fontSize: "18px",
      marginBottom: "55px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "14px",
      }
    },
    signUpCountryChevron: {
      height: "56px",
      borderLeft: "1px solid #CECECE",
      maxHeight: "none",
      paddingLeft: "13px",
      paddingRight: "6px",
      position: "absolute !important",
      right: "0 !important",
      pointerEvents: "none !important",
    },
    termsAndConditionsLabel: {
      fontWeight: "bold",
      marginTop: "3px",
      textAlign: "left"
    },
    confirmSignUpContainer: {
      maxWidth: "538px",
      borderRadius: "15px",
      marginTop: "0",
      padding: "0",
      border: "none",
      [theme.breakpoints.down("xs")]: {
        maxWidth: "none",
        width: "100%",
        borderRadius: "0",
        height: "calc(100vh - 124px)",
      }
    },
    // Main header styles
    headerWrapper: {
      display: "flex",
      height: "100px",
      margin: "0 auto 24px",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "34px 0 10px 0",
      [theme.breakpoints.down("sm")]: {
        padding: "24px 58px 0"
      },
      [theme.breakpoints.down("xs")]: {
        padding: "24px 36px 0"
      }
    },
    headerButton: {
      fontSize: "16px",
      color: "white",
      borderWidth: '2px',
      height: "50px",
      textAlign: "center",
      [theme.breakpoints.down("xs")]: {
        fontSize: "14px",
        width: "146px !important"
      }
    },
    signUpHeaderButton: {
      [theme.breakpoints.down("xs")]: {
        height: "56px",
        padding: "0",
      }
    },
    // Right bar
    signInRightBarWrapper: {
      backgroundColor: "#F0F0F0",
      width: "416px",
      borderRadius: "0 15px 15px 0",
      overflow: "hidden",
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },
    // Customer Reviews
    customerReviewWrapper: {
      padding: "0 19px"
    },
    reviewContentWrapper: {
      textAlign: "center",
      color: "#1F2533",
      marginTop: "26px",
      fontSize: "16px"
    },
    reviewerComponentWrapper: {
      display: "flex",
      justifyContent: "center",
      marginTop: "34px"
    },
    reviewerNameText: {
      fontSize: "16px",
      fontWeight: "bold"
    },
    underlineButton: {
      textDecoration: "underline",
      fontWeight: "bold"
    },
    removeAdornmentPadding: {
      "& .MuiIconButton-root": {
        padding: 0,
      }
    },
    removeInputAdornmentRightPadding: {
      "& .MuiOutlinedInput-adornedEnd": {
        paddingRight: 0,
      }
    },
    confirmSignUpHeadingWrapper: {
      display: "flex",
      margin: "0 auto 0 0"
    },
    confirmSignUpHeading: {
      fontSize: "24px",
      fontWeight: "bold",
      marginRight: "20px"
    },
    enterCodeText: {
      fontSize: "14px",
      fontWeight: "bold !important",
      color: "#1F2533"
    },
    resendCodeText: {
      textAlign: "left",
      fontSize: "14px"
    },
    resendCodeSubmitButton: {
      marginBottom: "28px",
      height: "50px"
    },
    mainLayoutHatchLogo: {
      width: "166px",
      [theme.breakpoints.down("xs")]: {
        width: "96px"
      }
    },
    profileSaveButton: {
      color: "white"
    }
  };
}
