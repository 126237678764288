import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

import HatchHeaderLogo from "../components/HatchHeaderLogo";
import { processRedirect } from "../lib/redirectLib";
import wilcomStyles from "../theme/WilcomStyles";
const useStyles = makeStyles(wilcomStyles);

export default function Home(props) {
  const classes = useStyles();
  const { forceSignIn } = props;
  useEffect(()=>processRedirect(forceSignIn),[forceSignIn])
  // processRedirect(forceSignIn);

  return (
    <div>
      <Container
        component="main"
        className={classes.mainContainer}
        maxWidth="sm"
      >
        <CssBaseline />
        <div className={classes.paper}>
          <HatchHeaderLogo />
          <Typography component="h1" variant="h5">
            Signing In ...
          </Typography>
          <br />
          <CircularProgress variant="indeterminate" size={36} thickness={4} />
          <br />
        </div>
      </Container>
    </div>
  );
}
